import React, { useState, useEffect, useContext } from "react";
import { Switch, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 0",
        borderTop: `1px solid ${theme.palette.primary.mainReverseByWhite}70`
    },
    title: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "bold",
        color: `${theme.palette.primary.mainReverseByWhite}`,
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    switchContainer: {
        display: "flex",
        alignItems: "center",
    },
    statusText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "500",
    },
}));

const PrivacySwitch = ({ contact, whatsAppId, isBlocked }) => {
    const [blocked, setBlocked] = useState(isBlocked);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setBlocked(isBlocked);
    }, [isBlocked]);

    const handleToggle = async () => {
        setBlocked(!blocked);
        setLoading(true);
        try {
            const url = blocked ? `/contacts/unblock` : `/contacts/block`;

            await api.post(url, { ...contact, contactNumber: contact.number, whatsappId: whatsAppId });
        } catch (err) {
            toastError(err);
            setBlocked(blocked);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <h3 className={classes.title}>Bloqueado</h3>
            <Switch
                size="small"
                checked={blocked}
                onChange={handleToggle}
                disabled={loading}
            />
            {loading && <CircularProgress size={24} />}
            <Typography variant="subtitle1">
                {!blocked ? "Off" : "On"}
            </Typography>
        </div>
    );
};

export default PrivacySwitch;
