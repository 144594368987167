import React from "react";
import { Button } from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";
import ImportButton from "./ImportButton";

const ActionButtons = ({
  whatsApp,
  handleOpenQrModal,
  handleRequestNewQrCode,
  setUpdatedWhatsApps,
  setSelectedWhatsApp,
  setQrModalOpen,
  handleOpenConfirmationModal,
  setConfirmModalInfo,
  setConfirmModalOpen,
  classes,
}) => {
  const renderActionButtons = () => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={() => handleOpenQrModal(whatsApp, setSelectedWhatsApp, setQrModalOpen)}
          >
            LER QRCODE
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            className={classes.addButton}
            onClick={() => handleRequestNewQrCode(whatsApp.id, setUpdatedWhatsApps)}
          >
            GERAR QR
          </Button>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className={classes.addButton}
              onClick={() => {
                handleOpenConfirmationModal("disconnect", whatsApp.id, setConfirmModalInfo, setConfirmModalOpen);
              }}
            >
              {i18n.t("connections.buttons.disconnect")}
            </Button>

            <ImportButton
              whatsApp={whatsApp}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalOpen={setConfirmModalOpen}
              handleOpenConfirmationModal={handleOpenConfirmationModal}
              classes={classes}
            />
          </>
        )}
        {whatsApp.status === "OPENING" && (
          <Button
            size="small"
            variant="outlined"
            disabled
            color="default"
            className={classes.addButton}
          >
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
      </>
    );
  };

  return <>{renderActionButtons()}</>;
};

export default ActionButtons;
