import React, { useEffect, useRef, useState } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckIcon from "@material-ui/icons/Check";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import CreateIcon from '@material-ui/icons/Create';
import ContactDetailsModal from "../ContactDetailsModal"; // ajuste o caminho conforme a estrutura do seu projeto

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader, useMediaQuery, useTheme } from "@material-ui/core";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";
import useStyles from "./styles";
import { TagsContainer } from "../TagsContainer";
import BotSwitch from "../BotSwitch";
import api from "../../services/api";
import PrivacySwitch from "../BlockUserSwitch";

const initialState = {
	name: "",
	number: "",
	email: "",
	disableBot: false,
};
const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading, user }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [modalOpen, setModalOpen] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [copied, setCopied] = useState(false);
	const [contactBotInfo, setContactBot] = useState(initialState)
	const [disableBot, setDisableBot] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [whatsAppId, setWhatsAppId] = useState(0);
	const isMounted = useRef(true);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false); // Para o modal de detalhes
	const [protocolo, setProtocolo] = useState(""); // Armazena o protocolo do último TicketTraking
	const [channelType, setChannelType] = useState(null); // Estado para armazenar o tipo de canal


	useEffect(() => {
  
	  if (!ticket.id) return;
  
	  const fetchChannelType = async () => {
		try {
		  const { data } = await api.get(`/tickets/${ticket.id}`);
		  // Atualiza o estado com o tipo de canal do WhatsApp
		  setChannelType(data.whatsapp?.type);
		} catch (err) {
		  // Mostra o erro em forma de toast
		  toastError(err);
		}
	  };
  
	  fetchChannelType(); // Chama a função para buscar o tipo de canal
  
	}, [ticket.id]); // O useEffect só roda quando o ticketId mudar
  
  
	useEffect(() => {
		const fetchProtocolo = async () => {
			if (!ticket.id) return;

			try {
				const { data } = await api.get(`/tickets/${ticket.id}/last-traking`);
				setProtocolo(data.protocolo);
			} catch (error) {
				console.error("Erro ao buscar protocolo:", error);
			}
		};

		fetchProtocolo();
	}, [ticket]);



	const handleSendProtocolo = async () => {
		try {
			if (channelType === "official") {
			  // Se o canal for oficial, usar a rota de mensagem oficial
			  await api.post(`/official-message/${ticket.id}`, {
				body: `Protocolo: ${protocolo}`});
			} else if (channelType !== null) {
			  // Se for um canal de hub
			  await api.post(`/hub-message/${ticket.id}`, {
				body: `Protocolo: ${protocolo}`});
			} else {
			  // Se for um canal padrão
			  await api.post(`/messages/${ticket.id}`, {
				body: `Protocolo: ${protocolo}`
			});
			}
		  } catch (err) {
			toastError(err);
	  
		  }
	};

	const handleOpenModal = () => {
		setDetailsModalOpen(true);
	};

	const handleCloseModal = () => {
		setDetailsModalOpen(false);
	};

	useEffect(() => {
		setOpenForm(false); // Reseta o estado do formulário de edição
		setModalOpen(false); // Reseta o estado do modal de detalhes
	}, [open, contact]);


	useEffect(() => {
		const fetchContact = async () => {

			if (!contact.id) return;

			try {
				const { data } = await api.get(`/contacts/${contact.id}`);
				if (isMounted.current) {
					setContactBot(data);
					setDisableBot(data.disableBot);
					setWhatsAppId(data.whatsappId);
					setIsBlocked(data.isBlocked)
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchContact();
	}, [contact, open]);
	useEffect(() => {
		setOpenForm(false);
	}, [open, contact]);

	function formatPhoneNumber(number) {
		let cleaned = ('' + number).replace(/\D/g, '');
		let match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

		if (match) {
			const firstPart = match[3].length === 5 ? `${match[3]}` : `${match[3].slice(0, 4)}-${match[3].slice(4)}`;
			return `+${match[1]} (${match[2]}) ${firstPart}-${match[4]}`;
		}

		return number;
	}

	const handleCopy = () => {
		navigator.clipboard.writeText(contact.number);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	const truncateEmail = (email) => {
		const [username, domain] = email.split("@");
		if (username.length > 7) {
			return `${username.slice(0, 7)}...@${domain}`;
		}

		return email;
	};

	const handleEmailCopy = () => {
		navigator.clipboard.writeText(contact.email).then(() => {
			alert("Email copiado!");
		});
	};
	const isMobile = useMediaQuery("(max-width: 900px)");
	return (
		<Drawer
			className={`${classes.drawer} cmp-tickets__mobile-drawer`}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{ paper: classes.drawerPaper + " cmp-ticket__drawer-paper" }}
		>
			<div className={classes.header}>
				<Typography style={{ justifySelf: "center", fontFamily: "Poppins", fontWeight: "700" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
				<div style={{ border: `2px solid ${theme.palette.primary.mainReverseByWhite}`, color: theme.palette.primary.mainReverseByWhite }} className={"cmp-contact-drawer__close-icon"} onClick={handleDrawerClose}>
					<CloseIcon width={"20px"} height={"20px"} />
				</div>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<CardHeader
							style={{ padding: "0 0 1rem 0" }}
							avatar={
								<Avatar
									src={contact.profilePicUrl}
									alt="contact_image"
									style={{ width: 60, height: 60, borderRadius: "8px" }}
								/>
							}
							title={
								<h4 className={classes.darkTexts} style={{ fontSize: "12px", fontWeight: "700", fontFamily: "Poppins" }}>
									{contact.name}
								</h4>
							}
							subheader={
								<>
									<Typography onClick={handleCopy} style={{ fontSize: 12, cursor: "pointer" }}>
										{formatPhoneNumber(contact.number)}
										<IconButton>
											{copied ? <CheckIcon style={{ color: "green" }} /> : <FileCopyIcon />}
										</IconButton>
									</Typography>
									<Tooltip title={contact.email} arrow>
										<Typography style={{ fontSize: 12 }}>
											<Link
												href={`mailto:${contact.email}`}
												onClick={(e) => {
													e.preventDefault(); // Evita redirecionamento
													handleEmailCopy();  // Copia o email
												}}
												style={{ cursor: "pointer" }}
											>
												{truncateEmail(contact.email)}
											</Link>
										</Typography>
									</Tooltip>
								</>
							}
						/>
						<>
							
							<Button
								variant="outlined"
								onClick={() => setModalOpen(!openForm)}
								className={`${classes.darkTexts} ${classes.darkBorder}`}
								style={{ fontSize: 12, maxWidth: "100%", borderRadius: "4px", cursor: "pointer" }}
							>
								{i18n.t("contactDrawer.buttons.edit")}
							</Button>
						
							<Button
								variant="outlined"
								onClick={handleOpenModal}
								className={`${classes.darkTexts} ${classes.darkBorder}`}
								style={{ marginTop: "1rem", fontSize: 12, maxWidth: "100%", borderRadius: "4px" }}
							>
								Ver Detalhes do Contrato
							</Button>

							{/* Renderiza o ContactForm se necessário */}
							{contact.id && openForm && (
								<ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />
							)}
						</>
					</Paper>

					<ContactDetailsModal
						contactId={contact.id}
						companyId={contact.companyId}
						open={detailsModalOpen}
						onClose={handleCloseModal}
					/>
					
					<Paper onClick={() => {
						navigator.clipboard.writeText(`Protocolo: ${protocolo}`);
					}} square variant="outlined" className={classes.contactDetails} style={{ borderBottom: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: "0 0 1rem 0" }}>
						<Typography variant="subtitle1" style={{ marginBottom: 10, color: `${theme.palette.primary.mainReverseByWhite}`, fontWeight: "700", fontFamily: "Poppins" }}>
							Protocolo: <span style={{fontSize: "12px"}}>{protocolo ? `${protocolo}` : "Carregando..."}</span>
						</Typography>
						<Button
							variant="outlined"
							onClick={handleSendProtocolo}
							className={`${classes.darkTexts} ${classes.darkBorder}`}
							style={{ marginTop: "1rem", fontSize: 12, maxWidth: "100%", borderRadius: "4px" }}
						>
							{protocolo ? `Enviar` : "Carregando..."}
						</Button>					</Paper>
					<Paper style={{ boxShadow: "none" }}>
						<TagsContainer ticket={ticket} />
					</Paper>
					<Paper square variant="outlined" className={classes.contactDetails} style={{ borderTop: `1px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: "1rem 0" }}>
						<ContactModal
							open={modalOpen}
							onClose={() => setModalOpen(false)}
							contactId={contact.id}
						></ContactModal>
						<Typography variant="subtitle1" style={{ color: `${theme.palette.primary.mainReverseByWhite}`, fontWeight: "bold", fontFamily: "Poppins", margin: "unset", margin: "0 0 1rem 0" }}>
							{i18n.t("contactDrawer.extraInfo")}
						</Typography>
						{contact?.extraInfo ? (contact?.extraInfo?.map(info => (
							<>
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel className={classes.darkTexts} style={{ fontWeight: "500" }}>{info.name}</InputLabel>
									<Typography component="div" noWrap style={{ paddingTop: 2 }} className={classes.contactExtraInfoItem}>
										<MarkdownWrapper>{info.value}</MarkdownWrapper>
									</Typography>
								</Paper>
							</>
						))) : <span>Nenhuma informação adicionada</span>}
					</Paper>
					<BotSwitch contactId={contact.id} contact={contact} initialDisableBot={disableBot} />
					<PrivacySwitch contact={contact} whatsAppId={whatsAppId} isBlocked={isBlocked} />
					<Paper square variant="outlined" className={classes.contactDetails} style={{ borderTop: `1px solid ${theme.palette.primary.mainReverseByWhite}70`, paddingTop: "1rem" }}>
						<Typography variant="subtitle1" style={{ marginBottom: 10, color: `${theme.palette.primary.mainReverseByWhite}`, fontWeight: "700", fontFamily: "Poppins" }}>
							{i18n.t("ticketOptionsMenu.appointmentsModal.title")}
						</Typography>
						<ContactNotes ticket={ticket} />
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactDrawer;
