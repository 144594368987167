import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	contactsHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0",
		marginBottom: "1rem",
		"& h5": {  // Seletor descendente para aplicar estilo ao h5 dentro de contactsHeader
			fontFamily: "Poppins",
			fontWeight: 700,
			fontSize: "16px",
			color: theme.palette.primary.mainReverseByWhite
		}
	},
}));

const MainHeader = ({ children, style = {} }) => {
	const classes = useStyles();

	return <div style={style} className={classes.contactsHeader}>{children}</div>;
};

export default MainHeader;
