import React from "react";
import { AccessTime } from "@material-ui/icons";

const TMaxAttendants = ({ attendants, classes, time }) => {
  const maxAttendTime = attendants?.maxAttendTime || "0:00";

  return (
    <div className={`cmp-dashboard__raw-data-item--first ${classes.dashBoardFirstItem}`}>
      <div>
        <h2>T.Max Atend</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <AccessTime />
        <span>{time}</span>
      </div>
    </div>
  );
};

export default TMaxAttendants;
