import React from "react";
import GroupIcon from "@material-ui/icons/Group";

const OnlineAttendants = ({ attendants, classes }) => {
  const onlineCount = attendants.filter((attendant) => attendant.online)?.length;
  return (
    <div className={`cmp-dashboard__raw-data-item--first ${classes.dashBoardFirstItem}`}>
      <div>
        <h2>Atendentes online</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <GroupIcon />
        <span>{onlineCount}</span>
        <div className="cmp-dashboard__online-circle"></div>
      </div>
    </div>
  );
};

export default OnlineAttendants;