import { useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const ScatterChart = ({ tickets }) => {
    const theme = useTheme();
    const { labels, npsData, supportTimeData } = useMemo(() => {
        if (tickets.length === 0) {
            return {
                labels: ['Placeholder'],
                npsData: [0],
                supportTimeData: [30], // Valor padrão de 30 minutos
            };
        }

        const labels = tickets.map(ticket => {
            const fullName = ticket.whatsappName || ticket.contactName || 'Desconhecido';
            return fullName.split(' ')[0]; 
        });

        const npsData = tickets.map(ticket => (ticket.NPS || 0) * 10);

        const parseSupportTime = (supportTime) => {
            const regex = /(?<days>\d+) d, (?<hours>\d+) hrs e (?<minutes>\d+) m/;
            const match = supportTime.match(regex);
            if (match && match.groups) {
                const days = parseInt(match.groups.days) || 0;
                const hours = parseInt(match.groups.hours) || 0;
                const minutes = parseInt(match.groups.minutes) || 0;
                return (days * 24 * 60) + (hours * 60) + minutes;
            }
            return 0;
        };

        const supportTimeData = tickets.map(ticket => parseSupportTime(ticket.supportTime));

        return { labels, npsData, supportTimeData };
    }, [tickets]); 

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Avaliação do Atendimento (min-0 max-30)',
                data: npsData,
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main + "50", 
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Tempo Médio de Atendimento (minutos)',
                data: supportTimeData,
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            labels: {
                fontColor: "black",
                fontWeight: 700
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Avaliação e Tempo de Atendimento',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Atendentes',
                },
                ticks: {
                    maxRotation: 45,
                    minRotation: 45,
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 20,
                left: 20,
                bottom: 30,
            },
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    // Exibir o valor real do NPS ao passar o mouse
                    if (context.dataset.label === 'Avaliação do Atendimento (NPS | 10-30)') {
                        const realNPS = context.raw / 10; // Divida por 10 para obter o valor original
                        return `NPS: ${realNPS.toFixed(1)}`; // Formata para uma casa decimal
                    }
                    return `${context.dataset.label}: ${context.raw}`;
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '220px' }}>
            <h3>{`Avaliação e Tempo dos Atendimentos`}</h3>
            <Line data={data} options={options} />
        </div>
    );
};

export default ScatterChart;
