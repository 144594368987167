import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: "red",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    background: theme.palette.drawerBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  submit: {
    "&.MuiButton-root": {
      margin: "20px 0px 16px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "4px",
      width: "100%"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "90",  
    },
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(3, 0, 2),
    WebkitTextFillColor: "#FFF",
    width: "50%",
    textTransform: "capitalize"
  },
  powered: {
    color: "white",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      position: "relative",
      borderRadius: "30px",
    },
  },
  logo: {
    paddingBottom: "50px",
    width: "400px",
    marginBottom: theme.spacing(3),
  },
  sliderContainer: {
    width: '400px',
  },
  carousel: {
    width: '100%',
    height: '300px',
  },
  carouselItem: {
    width: '100%',
    height: '300px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: 'white',
    padding: '10px',
    backgroundRepeat: "no-repeat"
  },

  text: {
    color: theme.palette.primary.main,
  },

  darkerTexts: {
    color: theme.palette.primary.third,
  },

  linkColor: {
    color: theme.palette.primary.links,
  },

  inputFocus: {
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.main} !important`,
    border: "1px solid transparent !important",
  },
}));

export default useStyles;