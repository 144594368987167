import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Card,
    CardContent,
    Paper,
    IconButton,
    Typography,
    Tooltip
} from "@material-ui/core";
import {
    Edit,
    DeleteOutline,
    Facebook,
    Instagram,
    WhatsApp
} from "@material-ui/icons";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import { Can } from "../../../../components/Can";
import { format, parseISO } from "date-fns";
import ActionButtons from '../buttons/ActionButtons';
import StatusToolTips from '../StatusTooltips';
import truncateText from '../../../../utils/truncateText';
import baileyIcon from "../../../../assets/baileys-icon.png"

const useStyles = makeStyles(theme => ({
    mainPaper: {
        background: "transparent",
        boxShadow: "none",
        display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #ddd",
        width: "calc((100% / 3) - 1rem)",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    cardBox: {
        display: "flex",
        width: "100%",
        gap: "1rem",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexGrow: 1,
        position: "relative"
    },
    iconTitleBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    statusIcon: {
        marginLeft: "10px",
    },
    centerText: {
        textAlign: "left",
    },
    actionButtons: {
        display: "flex",
        gap: "1rem",
        justifyContent: "flex-end",
        position: "absolute",
        top: "1rem",
        right: "1rem"
    },
    cardTitleStyles: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "bold",
        color: theme.palette.primary.mainReverseByWhite,
    },
    addButton: {
        color: theme.palette.primary.mainReverseByWhite,
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
}));

const ConnectionsCards = ({
    whatsApps,
    loading,
    user,
    setUpdatedWhatsApps,
    handleEditWhatsApp,
    handleRequestNewQrCode,
    handleOpenQrModal,
    setSelectedWhatsApp,
    setConfirmModalInfo,
    setModalType,
    setConfirmModalOpen,
    setWhatsAppModalOpen,
    setQrModalOpen,
    handleOpenConfirmationModal,
}) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const renderDetails = {
        instagram: { title: "Instagram", icon: <Instagram style={{ fontSize: 30, color: "#E1306C" }} /> },
        facebook: { title: "Facebook", icon: <Facebook style={{ fontSize: 30, color: "#3b5998" }} /> },
        official: { title: "Whatsapp", icon: <WhatsApp style={{ fontSize: 30, color: "#25D366" }} />, subtype: "Official" },
        default: { title: "Whatsapp", icon: <img style={{ maxWidth: "25px" }} alt="Whatsapp Official" src={baileyIcon} />, subtype: "Baileys" }
    };
    
    const renderTitleAndIcon = (type) => {
        return renderDetails[type] || renderDetails.default;
    };
    
    return (
        <Paper className={`${classes.mainPaper} cmp-connection_cards`}>
            {loading ? (
                <TableRowSkeleton />
            ) : (
                whatsApps?.length > 0 &&
                whatsApps.map(whatsApp => {
                    const { title, icon, subtype } = renderTitleAndIcon(whatsApp.type);
                    const isSocial = whatsApp.type === "instagram" || whatsApp.type === "facebook" || whatsApp.type === "official";
                    const isHub = whatsApp.type === "instagram" || whatsApp.type === "facebook";
                    return (
                        <Card className={`${classes.card} cmp-connection__card`} key={whatsApp.id}>
                            <CardContent className={classes.cardBox}>
                                <div className={classes.iconTitleBox}>
                                    <Typography variant="h6" className={classes.cardTitleStyles}>
                                        {title} <span style={{ fontSize: "10px", fontWeight: "400" }}>| {isHub ? "Hub Noti." : subtype}</span>
                                    </Typography>
                                </div>
                                
                                <div style={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "space-between", margin: "8px 0" }}>
                                    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                        {icon}
                                        <Tooltip title={whatsApp.name} arrow>
                                            <div style={{ fontSize: "10px", fontFamily: "Poppins", color: "gray" }}>
                                                {truncateText(whatsApp.name, 8)}
                                            </div>
                                        </Tooltip>
                                    </div>
                                    {!isSocial && (
                                        <Can
                                            role={user.profile}
                                            perform="connections-page:actionButtons"
                                            yes={() => (
                                                <ActionButtons
                                                    whatsApp={whatsApp}
                                                    setConfirmModalOpen={setConfirmModalOpen}
                                                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                                                    handleOpenQrModal={handleOpenQrModal}
                                                    classes={classes}
                                                    setConfirmModalInfo={setConfirmModalInfo}
                                                    setSelectedWhatsApp={setSelectedWhatsApp}
                                                    setQrModalOpen={setQrModalOpen}
                                                    setUpdatedWhatsApps={setUpdatedWhatsApps}
                                                    handleRequestNewQrCode={handleRequestNewQrCode}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                                
                                <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                    <Typography className={classes.cardTitleStyles}>Criado:</Typography>
                                    <StatusToolTips whatsApp={whatsApp} classes={classes} />
                                    <Typography>{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}</Typography>
                                </div>
                                
                                <Can
                                    role={user.profile}
                                    perform="connections-page:editOrDeleteConnection"
                                    yes={() => (
                                        <div className={`${classes.actionButtons} cmp-action-buttons`}>
                                            <IconButton size="small" className={classes.addButton} onClick={() => handleEditWhatsApp(whatsApp, whatsApp.type, setSelectedWhatsApp, setModalType, setWhatsAppModalOpen)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                className={classes.addButton}
                                                onClick={() => handleOpenConfirmationModal("delete", whatsApp.id, setConfirmModalInfo, setConfirmModalOpen)}
                                            >
                                                <DeleteOutline />
                                            </IconButton>
                                        </div>
                                    )}
                                />
                            </CardContent>
                        </Card>
                    );
                })
            )}
        </Paper>
    );
    
};

export default ConnectionsCards;
