import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import truncateText from "../../utils/truncateText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { LinearProgress, Card, CardContent, } from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CampaignModal from "../../components/CampaignModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Grid, Typography, Box } from "@material-ui/core";
import { get, isNull } from "lodash";
import { useDate } from "../../hooks/useDate";
import { socketConnection } from "../../services/socket";
import PreviewModal from "../../components/PreviewModal";
import PreviewList from "../../components/PreviewList";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CAMPAIGNS":
      return [...action.payload];

    case "UPDATE_PROGRESS":
      return state.map((c) =>
        c.id === action.payload.id
          ? { ...c, delivered: action.payload.delivered, validContacts: action.payload.validContacts }
          : c
      );

    case "UPDATE_CAMPAIGNS":
      return state.map((c) => (c.id === action.payload.id ? action.payload : c));

    case "DELETE_CAMPAIGN":
      return state.filter((c) => c.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    padding: "1rem"
  },
  tabContainer: {
    position: "relative",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px 8px 0 0",
    borderBottom: "none"
  },
  tableBody: {
    position: "relative",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "0 0 8px 8px",
    borderTop: "none",
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
  },
  buttonCampanha: {
    position: "absolute",
    right: "55%",
    top: "10px",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
    width: "25px",
    maxWidth: "25px",
    minWidth: "25px",
    padding: "0",
    margin: "0",
    borderRadius: "4px",
    height: "25px",
    top: "10px",
    color: `${theme.palette.primary.mainReverseByWhite}`
  },
  buttonPreview: {
    position: "absolute",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
    borderRadius: "4px",
    left: "95%",
    width: "25px",
    maxWidth: "25px",
    minWidth: "25px",
    padding: "0",
    margin: "0",
    height: "25px",
    top: "10px",
    color: `${theme.palette.primary.mainReverseByWhite}`
  },
  card: {
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    width: '320px', // Ajuste do tamanho do card
  },
  firstCard: {
    color: theme.palette.primary.mainReverseByWhite,
  },
  cardContent: {
    padding: theme.spacing(1),
    color: theme.palette.primary.mainReverseByWhite,
  },
  progress: {
    height: 10,
    borderRadius: 5,
    marginTop: theme.spacing(1),
  },
  actionIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

const Campaigns = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [deletingCampaign, setDeletingCampaign] = useState(null);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [campaigns, dispatch] = useReducer(reducer, []);
  const { datetimeToClient } = useDate();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [currentTab, setCurrentTab] = useState(0); // Estado para controlar a aba ativa
  const [previews, setPreviews] = useState([]);

  // Função para buscar os agendamentos
  const fetchPreviews = async () => {
    try {
      setLoading(true); // Certifique-se de que o loading esteja sendo ativado
      const { data } = await api.get("/schedules-preview");
      setPreviews(data); // Atualiza o estado com os novos dados
      console.log("Dados de previews recebidos:", data); // Certifique-se de que os dados estão corretos
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false); // Certifique-se de que o loading esteja sendo desativado
    }
  };


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const displayedCampaigns = campaigns.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const totalPages = Math.ceil(campaigns.length / itemsPerPage);
  const handleOpenPreviewModal = () => setIsPreviewModalOpen(true);
  const handleClosePreviewModal = () => {
    fetchPreviews();
    setIsPreviewModalOpen(false);
  };
  const handleConfirmPreviewModal = (options) => {
    fetchPreviews(); // Atualiza a lista após confirmar
    setSelectedOptions(options);
    console.log("Opções selecionadas:", options);
    fetchPreviews(); // Atualiza a lista através confirmar
  };

  // Função para alternar as abas
  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCampaigns();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPAIGN", payload: +data.id });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchCampaigns = async () => {
    try {
      const { data } = await api.get("/campaigns/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CAMPAIGNS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
      data.records.forEach((campaign) => {
        updateCampaignProgress(campaign.id);
      });
    } catch (err) {
      toastError(err);
    }
  };

  const updateCampaignProgress = async (campaignId) => {
    try {
      const { data } = await api.get(`/campaigns/${campaignId}`);
      const contactList = get(data, "contactList.contacts", []);
      const validContacts = contactList.filter((c) => c.isWhatsappValid).length;

      const shippingContacts = get(data, "shipping", []);
      const delivered = shippingContacts.filter((c) => !isNull(c.deliveredAt)).length;

      dispatch({
        type: "UPDATE_PROGRESS",
        payload: { id: campaignId, delivered, validContacts },
      });
    } catch (err) {
      console.error(`Erro ao atualizar progresso da campanha ${campaignId}:`, err);
    }
  };

  const calculateProgress = (campaign) => {
    if (campaign.validContacts === 0) return 0;
    return (campaign.delivered / campaign.validContacts) * 100;
  };

  const handleOpenCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      await api.delete(`/campaigns/${campaignId}`);
      toast.success(i18n.t("campaigns.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingCampaign(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return "Inativa";
      case "PROGRAMADA":
        return "Programada";
      case "EM_ANDAMENTO":
        return "Em Andamento";
      case "CANCELADA":
        return "Cancelada";
      case "FINALIZADA":
        return "Finalizada";
      default:
        return val;
    }
  };

  const cancelCampaign = async (campaign) => {
    try {
      await api.post(`/campaigns/${campaign.id}/cancel`);
      toast.success(i18n.t("campaigns.toasts.cancel"));
      setPageNumber(1);
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async (campaign) => {
    try {
      await api.post(`/campaigns/${campaign.id}/restart`);
      toast.success(i18n.t("campaigns.toasts.restart"));
      setPageNumber(1);
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingCampaign &&
          `${i18n.t("campaigns.confirmationModal.deleteTitle")} ${deletingCampaign.name}?`
        }
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <CampaignModal
        resetPagination={() => {
          setPageNumber(1);
          fetchCampaigns();
        }}
        open={campaignModalOpen}
        onClose={handleCloseCampaignModal}
        aria-labelledby="form-dialog-title"
        campaignId={selectedCampaign && selectedCampaign.id}
      />

      <PreviewModal
        isOpen={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        onConfirm={handleConfirmPreviewModal}
        onUpdate={fetchPreviews} // Passando a função de atualização
      />

      <MainHeader>
        <Grid style={{ width: "99.6%" }} container>
          <Grid xs={12} sm={8} item>
            <Title>{i18n.t("campaigns.title")}</Title>
          </Grid>
          <Grid xs={12} sm={4} item>
            <Grid spacing={2} container alignItems="center" justifyContent="flex-end">
              <Grid xs={8} sm={8} item>
                <TextField
                  fullWidth
                  placeholder={i18n.t("campaigns.searchPlaceholder")}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainHeader>
      <Paper square>
        <Box className={classes.tabContainer}>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab style={{ minWidth: "45%", fontWeight: "500", margin: "auto" }} label="Campanhas" />
            <Tab style={{ minWidth: "45%", fontWeight: "500", margin: "auto" }} label="Previews" />
          </Tabs>

          {/* Botões ao lado das labels */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCampaignModal}
            className={classes.buttonCampanha}
          >
            +
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPreviewModal}
            className={classes.buttonPreview}
          >
            +
          </Button>
        </Box>
      </Paper>

      <Paper className={`${classes.mainPaper} ${classes.tableBody}`} onScroll={handleScroll}>
        {currentTab === 0 && (
          campaigns.map((campaign, index) => (
            <Grid style={{ display: "flex", width: "unset" }} >
              <Card className={`${classes.card} ${index === 0 ? classes.firstCard : ''}`}>
                <CardContent className={classes.cardContent} >
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Nome:</strong>
                    <Tooltip title={campaign.name}>
                      <span> {truncateText(campaign.name)}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Status:</strong>
                    <Tooltip title={formatStatus(campaign.status)}>
                      <span> {truncateText(formatStatus(campaign.status))}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Lista de Contatos:</strong>
                    <Tooltip title={campaign.contactListId ? campaign.contactList.name : "Não definida"}>
                      <span> {truncateText(campaign.contactListId ? campaign.contactList.name : "Não definida")}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>WhatsApp:</strong>
                    <Tooltip title={campaign.whatsappId ? campaign.whatsapp.name : "Não definido"}>
                      <span> {truncateText(campaign.whatsappId ? campaign.whatsapp.name : "Não definido")}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Agendamento:</strong>
                    <Tooltip title={campaign.scheduledAt ? datetimeToClient(campaign.scheduledAt) : "Sem agendamento"}>
                      <span> {campaign.scheduledAt ? datetimeToClient(campaign.scheduledAt) : "Sem agendamento"}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Conclusão:</strong>
                    <Tooltip title={campaign.completedAt ? datetimeToClient(campaign.completedAt) : "Não concluída"}>
                      <span> {campaign.completedAt ? datetimeToClient(campaign.completedAt) : "Não concluída"}</span>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                    <strong>Confirmação:</strong>
                    <Tooltip title={campaign.confirmation ? "Habilitada" : "Desabilitada"}>
                      <span> {campaign.confirmation ? "Habilitada" : "Desabilitada"}</span>
                    </Tooltip>
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    className={classes.progress}
                    value={calculateProgress(campaign)}
                  />
                  <span>{campaign.delivered || 0} de {campaign.validContacts || 0}</span>
                  <div className={classes.actionIcons}>
                    {campaign.status === "EM_ANDAMENTO" && (
                      <IconButton onClick={() => cancelCampaign(campaign)} title="Parar Campanha" size="small">
                        <PauseCircleOutlineIcon />
                      </IconButton>
                    )}
                    {campaign.status === "CANCELADA" && (
                      <IconButton onClick={() => restartCampaign(campaign)} title="Reiniciar Campanha" size="small">
                        <PlayCircleOutlineIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={() => history.push(`/campaign/${campaign.id}/report`)} size="small">
                      <DescriptionIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => handleEditCampaign(campaign)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" onClick={() => { setConfirmModalOpen(true); setDeletingCampaign(campaign); }}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
              {loading && <TableRowSkeleton columns={8} />}

            </Grid>
          ))
        )}

        {currentTab === 1 && (
          <Box className="cmp-preview-table">
            <PreviewList key={Date.now()} onUpdate={fetchPreviews} />
          </Box>
        )}
        {currentTab === 0 && <Grid item xs={12} className={classes.pagination}>
          <Button
            onClick={() => handleChangePage(Math.max(currentPage - 1, 0))}
            disabled={currentPage === 0}
          >
            <ArrowBack />
          </Button>

          <Typography variant="body1" className={classes.pageNumber}>
            {currentPage + 1} / {totalPages}
          </Typography>

          <Button
            onClick={() => handleChangePage(Math.min(currentPage + 1, totalPages - 1))}
            disabled={currentPage >= totalPages - 1}
          >
            <ArrowForward />
          </Button>
        </Grid>}
      </Paper>

    </MainContainer>
  );
};

export default Campaigns;
