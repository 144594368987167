import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  TextField,
  Chip,
  Switch,
  CircularProgress,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useWhatsApps from '../../hooks/useWhatsApps';

const ManipulateGroupsComponent = () => {
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { whatsApps, loading } = useWhatsApps();
  const [switches, setSwitches] = useState({
    title: false,
    description: false,
    imageUrl: false,
    imageUpload: false,
    permissions: false,
  });
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null); 
  const [permissions, setPermissions] = useState(false);

  // Carregar grupos da conexão selecionada
  useEffect(() => {
    if (selectedConnection) {
      const fetchGroups = async () => {
        try {
          setIsLoadingGroups(true);
          const { data } = await api.get(`/api/groups/list-groups`, {
            params: { whatsappId: selectedConnection },
          });
          setGroups(data.groups);
        } catch (err) {
          toast.error('Erro ao buscar grupos.');
        } finally {
          setIsLoadingGroups(false);
        }
      };

      fetchGroups();
    }
  }, [selectedConnection]);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [name]: checked,
      // Esconder URL quando ativar upload e vice-versa
      ...(name === 'imageUpload' && checked && { imageUrl: false }),
      ...(name === 'imageUrl' && checked && { imageUpload: false }),
    }));
  };

  const handleAlter = async () => {
    if (!selectedGroups.length) {
      toast.error('Selecione pelo menos um grupo.');
      return;
    }

    setIsSaving(true);
    try {
      if (switches.title && newTitle) {
        await api.post('/api/groups/change-group-title', {
          whatsappId: selectedConnection,
          groupIds: selectedGroups,
          newTitle,
        });
      }

      if (switches.description && newDescription) {
        await api.post('/api/groups/change-group-description', {
          whatsappId: selectedConnection,
          groupIds: selectedGroups,
          newDescription,
        });
      }

      if (switches.imageUrl && imageUrl) {
        await api.post('/api/groups/change-group-image-url', {
          whatsappId: selectedConnection,
          groupIds: selectedGroups,
          imageUrl,
        });
      }

      if (switches.imageUpload && imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('whatsappId', selectedConnection);
        formData.append('groupIds', JSON.stringify(selectedGroups));

        await api.post('/api/groups/change-group-image-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      if (switches.permissions) {
        await api.post('/api/groups/change-group-permissions', {
          whatsappId: selectedConnection,
          groupIds: selectedGroups,
          restrictToAdmins: permissions,
        });
      }

      toast.success('Alterações aplicadas com sucesso!');
    } catch (error) {
      toast.error('Erro ao realizar as alterações.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClear = () => {
    setSelectedConnection('');
    setSelectedGroups([]);
    setGroups([]);
    setSwitches({
      title: false,
      description: false,
      imageUrl: false,
      imageUpload: false,
      permissions: false,
    });
    setNewTitle('');
    setNewDescription('');
    setImageUrl('');
    setImageFile(null);
    setPermissions(false);
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                Manipular Grupos
              </Typography>
              <Button variant="outlined" color="secondary" onClick={handleClear}>
                Limpar Tudo
              </Button>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  options={whatsApps.filter((contact) => contact.type === null)}
                  getOptionLabel={(option) => option.name || option.number}
                  renderInput={(params) => (
                    <TextField {...params} label="Conexão" variant="outlined" />
                  )}
                  value={whatsApps.filter((contact) => contact.type === null).find((whatsApp) => whatsApp.id === selectedConnection) || null}
                  onChange={(event, newValue) => setSelectedConnection(newValue ? newValue.id : '')}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={groups}
                  getOptionLabel={(option) => option.subject}
                  renderInput={(params) => (
                    <TextField {...params} label="Grupos" variant="outlined" />
                  )}
                  value={groups.filter((group) => selectedGroups.includes(group.id))}
                  onChange={(event, newValue) => setSelectedGroups(newValue.map((group) => group.id))}
                  disabled={!selectedConnection || groups.length === 0 || isLoadingGroups}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={option.id} label={option.subject} {...getTagProps({ index })} />
                    ))
                  }
                />
                {isLoadingGroups && <CircularProgress size={24} />}
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={switches.title} onChange={handleSwitchChange} name="title" />}
                  label="Alterar Título"
                />
                {switches.title && (
                  <TextField
                    label="Novo Título"
                    variant="outlined"
                    fullWidth
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    style={{ marginTop: 16 }}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={switches.description} onChange={handleSwitchChange} name="description" />}
                  label="Alterar Descrição"
                />
                {switches.description && (
                  <TextField
                    label="Nova Descrição"
                    variant="outlined"
                    fullWidth
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    style={{ marginTop: 16 }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={switches.imageUrl} onChange={handleSwitchChange} name="imageUrl" />}
                  label="Alterar Imagem (URL)"
                />
                {switches.imageUrl && (
                  <TextField
                    label="URL da Imagem"
                    variant="outlined"
                    fullWidth
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    style={{ marginTop: 16 }}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={switches.imageUpload} onChange={handleSwitchChange} name="imageUpload" />}
                  label="Alterar Imagem (Upload)"
                />
                {switches.imageUpload && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImageFile(e.target.files[0])}
                    style={{ marginTop: 16 }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={switches.permissions} onChange={handleSwitchChange} name="permissions" />}
                  label="Permissões de Envio de Mensagens"
                />
                {switches.permissions && (
                  <FormControl fullWidth variant="outlined" style={{ marginTop: 16 }}>
                    <InputLabel id="permissions-label">Permissões</InputLabel>
                    <Select
                      labelId="permissions-label"
                      value={permissions}
                      onChange={(e) => setPermissions(e.target.value)}
                      label="Permissões"
                    >
                      <MenuItem value={true}>Somente Administradores</MenuItem>
                      <MenuItem value={false}>Todos os Membros</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '40px' }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleAlter}
                  disabled={isSaving || !selectedGroups.length}
                >
                  {isSaving ? <CircularProgress size={24} /> : 'Alterar'}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" fullWidth color="default" onClick={handleClear}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManipulateGroupsComponent;
