import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	borderStyles: {
		borderRadius: "8px",
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},

	card: {
		width: "calc((100% / 3) - 1rem)",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
		},
	},
	cardHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		margin: "1rem 0",
	},
	cardIcon: {
		marginRight: theme.spacing(2),
		width: 40,
		height: 40,
		objectFit: "cover",
	},
	modalContent: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	cardBox: {
		display: "flex",
		flexWrap: "wrap",
		gap: "1rem",
		width: "100%",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column", // Em dispositivos móveis, muda para colunas
		},
	},
	actionButtons: {
		marginTop: "15px",
		display: "flex",
		gap: "1rem",
		justifyContent: "center",
	},

	cardTitleStyles: {
		fontFamily: "Poppins",
		fontSize: "14px",
		fontWeight: "bold",
		color: theme.palette.primary.mainReverseByWhite,
	},

	addButton: {
		color: theme.palette.primary.mainReverseByWhite,
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		borderRadius: "8px",
		background: "transparent",
		"&:hover": {
			color: theme.palette.primary.mainReverseByWhite,
			border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
			borderRadius: "8px",
			background: "transparent",
		},
	},

	cardsContainer: {
		background: "transparent",
		padding: "1rem",
		borderRadius: "8px",
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
	},

	numberStyles: {
		width: "80px",
		background: `${theme.palette.primary.main}70`,
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		color: "white",
		fontFamily: "Poppins",
		fontWeight: "bold",
		borderRadius: "8px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	borderBottomStyle: {
		borderBottom: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		padding: "0 0 2rem 0",
	},

	borderTopStyle: {
		borderTop: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		padding: "2rem 0 0 0",
	},

	graphsStyles: {
		width: "21.5%",
		position: "fixed",
		top: "73px",
		height: "calc(100vh - 99px)",
		right: "2rem",
		[theme.breakpoints.down('sm')]: {
			display: "none",
		},
	}
}));

export default useStyles;
