import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import "./style.css";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FilterListIcon from '@material-ui/icons/FilterList';
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button, Snackbar } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import api from "../../services/api";
import { TicketsListGroup } from "../TicketsListGroup";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles(theme => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTop: "none !important",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
  },
  },

  firstContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70 !important`,
    borderTop: "none !important",
  },
  darkerIcons: {
    color: theme.palette.primary.third
  },

  tabsHeader: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px 8px 0 0",
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderBottom: "none",
  },

  tabsInternal: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  snackbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1em",
    },
  },

  yesButton: {
    backgroundColor: "white",
    color: theme.palette.primary.main + " !important",
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "darkGreen",
      color: "#FFF",
    },
    borderRadius: 30,
  },
  noButton: {
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`, 
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "darkRed",
      color: "#FFF",
    },
    borderRadius: 30,
  },
  tab: {
    fontSize: "12px",
    minWidth: "auto",
    padding: "0",
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite
  },

  internalTab: {
    minWidth: 120,
    width: 120,
    padding: 5
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    background: "transparent",
    alignItems: "center",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderTop: "none",
    justifyContent: "space-between",
    borderBottom: "none",
  },

  ticketSearchLine: {
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    margin: "1rem",
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    marginTop: '1rem',
    padding: "0 1rem",
    borderRadius: "4px",
    borderBottom: `1px solid ${theme.palette.primary.main}70`
  },

  insiderTabPanel: {
    height: '100%',
    marginTop: "-72px",
    paddingTop: "72px"
  },

  insiderDoubleTabPanel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-72px",
    paddingTop: "72px",
    height: "100%"
  },

  labelContainer: {
    width: "auto",
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: "row",
    '& > *:first-child': {
      marginBottom: '3px !important',
      marginRight: 16
    }
  },
  insiderTabLabel: {
    [theme.breakpoints.down(1600)]: {
      display: 'none'
    }
  },
  smallFormControl: {
    '& .MuiOutlinedInput-input': {
      padding: "12px 10px",
    },
    '& .MuiInputLabel-outlined': {
      marginTop: "-6px"
    }
  }
}));

const TicketsManagerTabs = ({setMobileUserOpen, TicketsListCustom, setHideMessages}) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const handleElementClick = (event) => {
      const target = event.target;
      if (!target.classList.contains('cmp-tickers__close-every-ticket')) {
        const closeEveryTicketElement = document.querySelector('.cmp-tickers__close-every-ticket');

        if (closeEveryTicketElement) {
          closeEveryTicketElement.classList.add('cmp-tickers__close-every-ticket--disabled');
        }
      }
    };

    document.addEventListener('click', handleElementClick);

    return () => {
      document.removeEventListener('click', handleElementClick);
    };
  }, []);
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [filterModalOpen, setFilterModal] = useState(false);
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showGroupTab, setShowTabGroup] = useState(false);
  const theme = useTheme();
  useEffect(() => {

    fetchSettings();

    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const fetchSettings = async () => {
    try {
      const { data } = await api.get("/settings");
      const showGroups = data.find((s) => s.key === "CheckMsgIsGroup");
      setShowTabGroup(showGroups.value === "disabled");
    } catch (err) {
      // toastError(err);
    }
  };

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  const CloseAllTicket = async () => {
    try {
      const { data } = await api.post("/tickets/closeAll", {
        status: tabOpen,
        selectedQueueIds,
      });

      handleSnackbarClose();

    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper elevation={0} variant="outlined" className={`${classes.ticketsWrapper} cmp-tickets__rigth-container`}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {

          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          className="cmp-atendimento__header"
          aria-label="icon label tabs example"
          style={{
            alignItems: "center"
          }}
        >
          <Tab
            value={"open"}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
            style={{ width: 80, color: theme.palette.primary.mainReverseByWhite }} // Definindo a largura da primeira aba
          />

          {showGroupTab && (
            <Tab
              value={"group"}
              label={i18n.t("tickets.tabs.group.title")}
              classes={{ root: classes.tab }}
              style={{ width: 80 }} // Definindo a largura da segunda aba
            />
          )}

          <Tab
            value={"search"}
            icon={<SearchIcon />}
            title={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBoxIcon colorSecondary="red" colorPrimary="blue" style={{
              backgroundColor: "transparent",
              border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
              borderRadius: "4px",
              color: theme.palette.primary.mainReverseByWhite
            }} />}
            title={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />

          {(tab !== "search" && tab !== "closed") && (
            <Button
              variant="outlined"
              color="primary"
              title="Filtros"
              onClick={() => {
                setFilterModal(!filterModalOpen);
              }}
              style={{
                minWidth: "24px",
                width: "24px",
                height: "24px",
                fontSize: "0.5rem",
                padding: "0",
                border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                color: theme.palette.primary.mainReverseByWhite,
                fontWeight: "bold"
              }}
            >
              <FilterListIcon />
            </Button>
          )}

          <Button
            variant="outlined"
            color="primary"
            title={i18n.t("ticketsManager.buttons.newTicket")}
            onClick={() => setNewTicketModalOpen(true)}
            style={{
              minWidth: "24px",
              width: "24px",
              height: "24px",
              fontSize: "0.7rem",
              padding: "0",
              border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
              color: theme.palette.primary.mainReverseByWhite,
              fontWeight: "bold"
            }}
          >
            {i18n.t("ticketsManager.buttons.newTicket")}
          </Button>
        </Tabs>
      </Paper>


      <Paper
        square
        elevation={0}
        className={`${classes.ticketOptionsBox} ${filterModalOpen
          ? "cmp-tickers__close-every-ticket"
          : "cmp-tickers__close-every-ticket--disabled"}`}>
        {(tab !== "search" && tab !== "closed") && <div className={`cmp-tickets__filter-modal `}>
          <Snackbar
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            message={i18n.t("tickets.inbox.closedAllTickets")}
            ContentProps={{
              className: `${classes.snackbar}`,
            }}
            action={
              <>
                <Button
                  className={classes.yesButton}
                  size="small"
                  onClick={CloseAllTicket}
                >
                  {i18n.t("tickets.inbox.yes")}
                </Button>
                <Button
                  className={classes.noButton}
                  size="small"
                  onClick={handleSnackbarClose}
                >
                  {i18n.t("tickets.inbox.no")}
                </Button>
              </>
            }
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSnackbarOpen}
            style={{
              minWidth: "90px", fontSize: "0.7rem",
              "@media (max-width: 950px)": {
                fontSize: "0.5rem",
              },
              color: theme.palette.primary.mainReverseByWhite, border: `1px solid ${theme.palette.primary.mainReverseByWhite}`
            }} // Define o tamanho mínimo e a fonte
          >
            {i18n.t("ticketsManager.buttons.closeallTicket")}

          </Button>

        </div>}
        <TicketsQueueSelect
          style={{ marginLeft: 6 }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
          display={(tab !== "search" && tab !== "closed") ? "flex" : "none"}
        />
      </Paper>

      <TabPanel value={tab} name="open" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className="cmp-tickets__header-buttons"
        >
          <Tab
            label={
              <Badge
                className={`${classes.badge}`}
                badgeContent={openCount}
                color="primary"
              >
                <HeadsetMicIcon className={classes.darkerIcons}/>
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                <NotificationsIcon className={classes.darkerIcons} />
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper className={`${classes.ticketsWrapper}`}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            setHideMessages={setHideMessages}
            selectedQueueIds={selectedQueueIds}
            setMobileUserOpen={setMobileUserOpen}
            TicketsListCustom={TicketsListCustom}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="group" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className="cmp-tickets__header-buttons"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                <HeadsetMicIcon className={classes.darkerIcons} />
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                <NotificationsIcon className={classes.darkerIcons} />
              </Badge>
            }
            value={"pending"}
          />
        </Tabs>
        <Paper className={`${classes.ticketsWrapper}`}>
          <TicketsListGroup
            status="open"
            showAll={true}
            setHideMessages={setHideMessages}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            setMobileUserOpen={setMobileUserOpen}
            style={applyPanelStyle("open")}
          />
          <TicketsListGroup
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>

      <TabPanel value={tab} name="closed" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
        <TicketsList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>

      <TabPanel value={tab} name="search" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder") + "..."}
              type="search"
              onChange={handleSearch}
            />
          </div>
        ) : (
          <>
          </>
        )}
        <div className="cmp-tickets__search-fiter-container">
          {profile === "admin" && (
            <UsersFilter onFiltered={handleSelectedUsers} />
          )}
          <TagsFilter onFiltered={handleSelectedTags} />
        </div>
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          setMobileUserOpen={setMobileUserOpen}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManagerTabs;
