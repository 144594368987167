import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import "./styles/style.css";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Grid, useMediaQuery } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginHeader from "../../components/LoginHeader";
import InfoSlider from "../../components/InfoSlider";
import useStyles from "./styles/style";
import {
  savePasswordToLocalStorage,
  getPasswordFromLocalStorage,
  getEmailFromLocalStorage
} from "./encrypt/index"
import Footer from "../../components/Footer";
import LoginForm from "../../components/CheckoutPage/Forms/LoginForm";

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "", rememberMe: false });
  const { handleLogin } = useContext(AuthContext);
  const isMobile = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    const savedPassword = getPasswordFromLocalStorage();
    const savedEmail = getEmailFromLocalStorage();

    if (savedPassword) {
      setUser((prevUser) => ({ ...prevUser, password: savedPassword }));
    }
    if (savedEmail) {
      setUser((prevUser) => ({ ...prevUser, email: savedEmail, rememberMe: true }));
    }
  }, []);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.rememberMe) {
      savePasswordToLocalStorage(user.password);
      localStorage.setItem('user_email', user.email);
    } else {
      localStorage.removeItem('user_email');
    }
    handleLogin(user, isMobile);
  };

  return (
    <div className={`geral ${classes.paper}`}>
      <LoginHeader />
      <LoginForm
        classes={classes}
        handleSubmit={handleSubmit}
        user={user}
        setUser={setUser}
        handleChangeInput={handleChangeInput}
      />
      <Footer classes={classes} />
    </div>
  );
};

export default Login;

