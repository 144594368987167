import React from "react";

import {
	Typography,
	CircularProgress,
	Card,
	CardContent,
} from "@material-ui/core";

import { i18n } from "../../../../translate/i18n";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
  
const StatusImport = ({statusImport, classes}) => {
	return (
        <>
			{statusImport?.all ? (
				<>
					<div style={{ margin: "auto", marginBottom: 12 }}>
						<Card className={classes.root}>
							<CardContent className={classes.content}>
								<Typography component="h5" variant="h5">
									{statusImport?.this === -1 ? i18n.t("connections.buttons.preparing") : i18n.t("connections.buttons.importing")}
								</Typography>
								{statusImport?.this === -1 ?
									<Typography component="h6" variant="h6" align="center">
										<CircularProgress
											size={24}
										/>
									</Typography>
									:
									<>
										<Typography component="h6" variant="h6" align="center">
											{`${i18n.t(`connections.typography.processed`)} ${statusImport?.this} ${i18n.t(`connections.typography.in`)} ${statusImport?.all}  ${i18n.t(`connections.typography.date`)}: ${statusImport?.date} `}
										</Typography>
										<Typography align="center">
											<CircularProgressWithLabel
												style={{ margin: "auto" }}
												value={(statusImport?.this / statusImport?.all) * 100}
											/>
										</Typography>
									</>


								}

							</CardContent>
						</Card>
					</div>
				</>
			) : null}
        </>
    );
}

export default StatusImport;