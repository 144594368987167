import React from "react";
import { add } from "date-fns";
import { Button, CircularProgress } from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";

const ImportButton = ({ whatsApp, handleOpenConfirmationModal, classes, setConfirmModalInfo, setConfirmModalOpen }) => {
  const renderImportButton = () => {
    if (whatsApp?.statusImportMessages === "renderButtonCloseTickets") {
      return (
        <Button
          style={{ marginLeft: 12 }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            handleOpenConfirmationModal("closedImported", whatsApp.id, setConfirmModalInfo, setConfirmModalOpen);
          }}
        >
          {i18n.t("connections.buttons.closedImported")}
        </Button>
      );
    }

    if (whatsApp?.importOldMessages) {
      let isTimeStamp = !isNaN(
        new Date(Math.floor(whatsApp?.statusImportMessages)).getTime()
      );

      if (isTimeStamp) {
        const ultimoStatus = new Date(
          Math.floor(whatsApp?.statusImportMessages)
        ).getTime();
        const dataLimite = +add(ultimoStatus, { seconds: 35 }).getTime();
        if (dataLimite > new Date().getTime()) {
          return (
            <Button
              disabled
              style={{ marginLeft: 12 }}
              size="small"
              endIcon={
                <CircularProgress
                  size={12}
                  className={classes.buttonProgress}
                />
              }
              variant="outlined"
              color="primary"
            >
              {i18n.t("connections.buttons.preparing")}
            </Button>
          );
        }
      }
    }

    return null;
  };

  return <>{renderImportButton()}</>;
};

export default ImportButton;
