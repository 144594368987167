
import React from "react";

import SpeedIcon from "@material-ui/icons/Speed";
import PersonIcon from "@material-ui/icons/Person";

import CardCounter from "../Dashboard/CardCounter";

const DashboardAdditionalInfo = ({ counters, formatTime, loading}) => {

    return (
        <div className="cmp-dashboard__plus-info-container">
            <CardCounter
                icon={<PersonIcon fontSize="inherit" />}
                title="Leads"
                value={counters.leads}
                loading={loading}
            />
            <CardCounter
                icon={<SpeedIcon fontSize="inherit" />}
                title="T.M. de Atendimento"
                value={formatTime(counters.avgSupportTime)}
                loading={loading}
            />
        </div>
    );
};

export default DashboardAdditionalInfo;
