import React from "react";
import { Card, CardContent, Typography, Tooltip } from "@material-ui/core";
import TrafficDonutChart from "./TrafficDonutChart";

const ConnectionGraphs = ({ classes, totalCanais, canaisUtilizados, trafegoSite }) => {
    // Cálculo da porcentagem de canais utilizados
    const usedPercentage = (canaisUtilizados / totalCanais) * 100;

    return (
        <div className={`${classes.card} ${classes.borderStyles} ${classes.graphsStyles}`}>
            <Card style={{ height: "100%" }}>
                <CardContent style={{ height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <div className={classes.borderBottomStyle}>
                        <h4 className={classes.cardTitleStyles}>Total de Canais</h4>
                        <div style={{ display: "flex", justifyContent: "center", gap: "3rem", marginTop: "1rem" }}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Typography variant="body1" gutterBottom>
                                    Canais
                                </Typography>
                                <p className={classes.numberStyles}>
                                    {totalCanais}
                                </p>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Typography variant="body1" gutterBottom>
                                    Utilizados
                                </Typography>
                                <p className={classes.numberStyles}>
                                    {canaisUtilizados}
                                </p>
                            </div>
                        </div>
                    </div>
					 <div>
					 	<h4 className={classes.cardTitleStyles} >Conectados x Desconectados</h4>
                        <Tooltip  title={`${canaisUtilizados} de ${totalCanais} canais utilizados`} arrow>
                            <div style={{
                                height: "10px",
                                backgroundColor: "red", // Parte de trás vermelha
                                borderRadius: "5px",
                                overflow: "hidden",
								marginTop: "1rem"
                            }}>
                                <div style={{
                                    height: "100%",
                                    width: `${usedPercentage}%`,
                                    backgroundColor: "#4caf50", // Cor verde
                                    transition: "width 0.3s ease-in-out"
                                }} />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={classes.borderTopStyle}>
                        <h4 className={classes.cardTitleStyles}>Tráfego</h4>
                        <TrafficDonutChart trafegoSite={trafegoSite} />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ConnectionGraphs;
