import React, { useState, useEffect, useRef } from "react";

import Container from "@material-ui/core/Container";

import SpeedIcon from "@material-ui/icons/Speed";
import PersonIcon from "@material-ui/icons/Person";
import useTickets from "../../hooks/useTickets";

import { toast } from "react-toastify";
import "./dashboard.css"

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardFilter from "../../components/DashboardFilter";
import DashboardGraphs from "../../components/DashboardGraphs";
import useStyles from "./theme";
import DashboardAdditionalInfo from "../../components/DashboardAdditionalInfo";
import { useMediaQuery, useTheme } from "@material-ui/core";
import DashboardSubheader from "../../components/DashboardSubheader";
import { useHistory } from "react-router-dom";
const Dashboard = () => {
  const theme = useTheme();

  const classes = useStyles(theme);
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [lastTickets, setLastTickets] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [reportCount, setReportCounts] = useState(null);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [serviceQuality, setServiceQuality] = useState(null); // Novo estado
  const history = useHistory();
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find, getReport, getServiceQuality } = useDashboard();
  const { finding } = useCompanies();
  const isMobile = useMediaQuery("(max-width: 900px)");
  useEffect(() => {
    async function firstLoad() {
      await fetchData();
      await fetchServiceQuality(); // Chama a função para buscar a qualidade do serviço
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    if(isMobile) {
      history.push("/tickets")
    }
  }, []);



  async function fetchServiceQuality() {
    if (companyId) {
      try {
        const data = await getServiceQuality(companyId);
        setServiceQuality(data.serviceQualityPercentage);
      } catch (error) {
        console.error("Erro ao buscar qualidade do serviço:", error);
      }
    }
  }

  

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
    async function fetchReport() {
      const data = await getReport({
        searchParam: "",
        whatsappId: [],
        users: [],
        queueIds: [],
        status: [],
        categoryIds: [],
        dateFrom: dateFrom,
        dateTo: dateTo,
        page: 1,
        pageSize: 20,
      });

      setLastTickets(data.tickets);
      setReportCounts(data);
    };

    fetchReport()
    
  }, []);

  const companyId = localStorage.getItem("companyId");
  
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }
  const date = useRef(new Date().toISOString());
  const { tickets } = useTickets({ date: date.current });

  return (
    <div>
      <div className={classes.container}>
      <div style={{display: "flex", gap:"2rem", flexDirection: "column"}}>
        <DashboardHeader classes={classes} attendants={attendants} counters={counters} serviceQuality={serviceQuality} />
        <DashboardSubheader classes={classes} attendants={attendants} counters={counters} reportCount={reportCount}/>
      </div>
        <DashboardGraphs
            classes={classes}
            theme={theme}
            attendants={attendants}
            counters={counters}
            lastTickets={lastTickets}
            serviceQuality={serviceQuality} // Passa a qualidade do serviço como prop
            tickets={tickets}
          />
        <DashboardFilter
          filterType={filterType}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          classes={classes}
          setDateTo={setDateTo}
          period={period}
          loading={loading}
          handleChangePeriod={handleChangePeriod}
          handleChangeFilterType={handleChangeFilterType}
          fetchData={fetchData}
        />
        <div className={"cmp-dashboard__attendants-table"}>
          {attendants.length ? (
            <TableAttendantsStatus
              counters={counters}
              attendants={attendants}
              loading={loading}
              classes={classes}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
