// SocialCard.js
import React from 'react';
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { Can } from '../Can';

const SocialCard = ({ title, subtitle, iconSrc, isDisabled, onButtonClick, classes, user, incoming }) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" className={classes.cardTitleStyles}>
            {title} <span style={{ fontWeight: "400" }}> | {subtitle}</span>
          </Typography>
        </div>
        <div className={classes.cardHeader}>
          <img
            src={iconSrc}
            alt={`${title} icon`}
            className={classes.cardIcon}
          />
          <Can
            role={user.profile}
            perform="connections-page:addConnection"
            yes={() => (
              <Button
                variant="contained"
                color="primary"
                disabled={isDisabled}
                onClick={onButtonClick}
                className={classes.addButton}
              >
                {incoming ? "Em breve" : "Adicionar"}
              </Button>
            )}
          />
        </div>
        <Typography variant="h5" className={classes.cardTitleStyles} style={{ fontSize: "14px" }}>
          {title} <span style={{ fontWeight: "400" }}> | {subtitle}</span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SocialCard;
