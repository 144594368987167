import React, { useState, useEffect, useContext, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { i18n } from "../../translate/i18n";
import { CircularProgress, FormControl, Grid, IconButton, TextField, Tooltip, Typography, Chip } from "@material-ui/core";
import { UsersFilter } from "../../components/UsersFilter";
import { WhatsappsFilter } from "../../components/WhatsappsFilter";
import { StatusFilter } from "../../components/StatusFilter";
import QueueSelect from "../../components/QueueSelect";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterList from "@material-ui/icons/FilterList";


const FilterComponent = ({
    classes,
    loading,
    setSearchParam,
    setSelectedContactId,
    setSelectedWhatsapp,
    setSelectedStatus,
    queueIds,
    setQueueIds,
    setUserIds,
    options,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    categoryIds,
    setCategoryIds,
    timeDifference,
    setTimeDifference,
    categories,
    handleFilter
}) => {

    return (
        <>
            <Paper style={{ width: "100%"}} className={classes.filterContainer}>
                <Typography variant="h6" className={classes.headerText}>Filtros de Pesquisa</Typography>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            options={options}
                            loading={loading}
                            size="small"
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t("newTicketModal.fieldLabel")}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setSearchParam(e.target.value)}
                                />
                            )}
                            onChange={(e, newValue) => setSelectedContactId(newValue?.id || null)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}><WhatsappsFilter onFiltered={setSelectedWhatsapp} /></Grid>
                    <Grid item xs={12} md={3}><StatusFilter onFiltered={setSelectedStatus} /></Grid>
                    <Grid item xs={12} md={3}><UsersFilter onFiltered={setUserIds} /></Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <QueueSelect selectedQueueIds={queueIds} onChange={setQueueIds} fullWidth />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Qualidade (s)"
                            type="number"
                            value={timeDifference}
                            onChange={(e) => setTimeDifference(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    {/* Tabulação */}
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            multiple
                            options={categories.flatMap(cat => [cat, ...(cat.subcategories || [])])} // Inclui categorias e subcategorias
                            getOptionLabel={(option) => option.name}
                            value={categories
                                .flatMap(cat => [cat, ...(cat.subcategories || [])]) // Inclui categorias e subcategorias no value
                                .filter(cat => categoryIds.includes(cat.id))
                            }
                            onChange={(e, newValue) => {
                                // Extrair IDs de todas as opções selecionadas
                                const selectedIds = newValue.map(val => val.id);

                                // Atualiza o estado com os IDs selecionados
                                setCategoryIds(selectedIds);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.name}
                                        {...getTagProps({ index })}
                                        style={{
                                            backgroundColor: option.parent_id ? "#bfbfbf" : "#ffc107",
                                            color: "#fff",
                                            textShadow: "1px 1px 1px #000"
                                        }}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tabulação"
                                    size="small"
                                    fullWidth
                                />
                            )}
                            renderOption={(option) => {
                                const isSubcategory = option.parent_id !== null;
                                return (
                                    <span style={{ paddingLeft: isSubcategory ? 20 : 0, fontWeight: isSubcategory ? 'normal' : 'bold' }}>
                                        {option.name}
                                    </span>
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.actionButtons}>
                        <Button variant="contained" color="primary" onClick={() => handleFilter(1)}>
                            {i18n.t("reports.buttons.filter")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default FilterComponent;
