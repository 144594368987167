import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		[theme.breakpoints.down('sm')]: {
            display: 'none',
        },
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderRadius: "8px",
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)"
	},
	header: {
		display: "flex",
		backgroundColor: "transparent", //DARK MODE PLW DESIGN//
		alignItems: "center",
		padding: "0 1rem",
		minHeight: "48px",
		fontFamily: "Poppins",
		fontWeight: "700",
		fontSize: "12px",
		color: `${theme.palette.primary.mainReverseByWhite}`,
		justifyContent: "space-between",
	},
	content: {
		display: "flex",
		backgroundColor: "transparent", //DARK MODE PLW DESIGN//
		flexDirection: "column",
		padding: "0 1rem 1rem 1rem",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		borderBottom: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		paddingBottom: "1rem",
		border: "none",
		"& > *": {
		},
	},

	contactDetails: {
		display: "flex",
		flexDirection: "column",
		border: "none",
		marginTop: "auto"
	},
	contactExtraInfo: {
		border: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	darkTexts: {
		color: theme.palette.tertiary
	},
	darkBorder: {
		border: `2px solid ${theme.palette.primary.third} !important`
	},
	extraInfoItem: {
		color: `${theme.palette.blackOrWhite} !important`
	}
}));

export default useStyles;