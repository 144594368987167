import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import api from '../../services/api'; // Importando o serviço de API
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const UserSelectModal = ({ contactId, onClose }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch users from the company
    api
      .get('/users/list')
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Falha ao buscar usuários');
        setLoading(false);
      });
  }, []);

  const handleUserSelection = (event) => {
    setSelectedUserId(event.target.value);
  };

  const attachUserToContact = () => {
    if (!selectedUserId) return;

    // Obter o companyId do localStorage
    const companyId = localStorage.getItem('companyId');

    api
      .put(`/contacts/attach-user/${contactId}`, { userId: selectedUserId, companyId })
      .then((response) => {
        console.log('Usúario anexado ao contato', response.data);
        toast.success('Usúario anexado ao contato');
        onClose(); // Close the modal
      })
      .catch((error) => {
        toastError('Failed to attach user');
        console.error('Failed to attach user:', error);
        setError('Failed to attach user');
      });
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Selecione o Usúrio</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel>User</InputLabel>
            <Select value={selectedUserId} onChange={handleUserSelection}>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">    
          Close
        </Button>
        <Button onClick={attachUserToContact} color="primary" disabled={!selectedUserId}>
          Attach User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectModal;
