import React, { useContext, useState, useRef, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { Replay, Phone, CallEnd } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import callingSound from "../../assets/sounds/calling.mp3";
import VideocamIcon from '@material-ui/icons/Videocam'; // Importe o ícone de vídeo
import "./style.css";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		display: "flex",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	userButton: {
		color: theme.palette.primary.mainReverseByWhite,
		background: 'none',
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
	},
	iconButton: {
		color: theme.palette.primary.mainReverseByWhite,
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
	},
}));

const TicketActionButtonsCustom = ({ ticket, handleDrawerOpen, handleDrawerClose, drawerOpen, contact, setMobileUserOpen, setMobileAdditionalInfoOpen }) => { // Adicionando toggleContactInfo como prop
	const [whatsappInstance, setWhatsappInstance] = useState(null);
	const [isCalling, setIsCalling] = useState(false);
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [callDuration, setCallDuration] = useState(0); // Estado para o tempo de chamada
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const classes = useStyles();
	const audioRef = useRef(null);
	const { user } = useContext(AuthContext);
	const isCallsEnabled = true;
	const isMobile = useMediaQuery("(max-width: 900px)");

	const [channelType, setChannelType] = useState(null); // Estado para armazenar o tipo de canal


	useEffect(() => {
  
	  if (!ticket.id) return;
  
	  const fetchChannelType = async () => {
		try {
		  const { data } = await api.get(`/tickets/${ticket.id}`);
		  // Atualiza o estado com o tipo de canal do WhatsApp
		  setChannelType(data.whatsapp?.type);
		} catch (err) {
		  // Mostra o erro em forma de toast
		  toastError(err);
		}
	  };
  
	  fetchChannelType(); // Chama a função para buscar o tipo de canal
  
	}, [ticket.id]); // O useEffect só roda quando o ticketId mudar
  
	
	const handleCloseTicketOptionsMenu = () => {
		setAnchorEl(null);
	};
	const handleSendMeet = async () => {
		try {
		  // Faz a requisição para criar a reunião Jitsi
		  const response = await api.post("/jitsi/create-meeting", {
			roomName: "sala-de-reuniao",
			userName: "Nome do Usuário"
		  });
	  
		  // Pega o link da reunião da resposta
		  const meetingLink = response.data.meetingLink;
	  
		  try {
			if (channelType === "official") {
			  // Se o canal for oficial, usar a rota de mensagem oficial
			  await api.post(`/official-message/${ticket.id}`, {
				body: `Seu link de reunião: ${meetingLink}`
			  });
			} else if (channelType !== null) {
			  // Se for um canal de hub
			  await api.post(`/hub-message/${ticket.id}`, {
				body: `Seu link de reunião: ${meetingLink}`
			  });
			} else {
			  // Se for um canal padrão
			  await api.post(`/messages/${ticket.id}`, {
				body: `Seu link de reunião: ${meetingLink}`
			  });
			}

	  
			toast.success("Link da reunião enviado com sucesso!");
		  } catch (error) {
			toast.error("Erro ao enviar o link da reunião");
		  }
		} catch (error) {
		  // Tratamento de erro para a criação da reunião
		  toast.error("Erro ao criar a reunião Jitsi");
		}
	  };
	  

	// Função para inicializar a instância do Wavoip e garantir que ela seja criada antes de tentar iniciar a chamada
	const initializeInstance = async () => {
		try {
			const { data: userData } = await api.get(`/contacts/${contact.id}`);

			const response = await api.get('/api/get-tokenwavoip', {
				params: {
					whatsappId: userData.whatsappId,
					companyId: user.company.id
				}
			});

			const { tokenwavoip } = response.data;
			const Wavoip = require("wavoip-api");
			const instance = new Wavoip();
			const connectedInstance = instance.connect(tokenwavoip);

			connectedInstance.socket.on('connect', () => {
				console.log("[DEBUG] - Socket conectado com sucesso!");
			});

			// Listener para eventos de signaling
			connectedInstance.socket.on('signaling', async (eventData) => {
				console.log("[DEBUG] - Evento de sinalização recebido:", eventData);

				switch (eventData.tag) {
					case 'accept':
						console.log("[SIGNALING EVENT] - Chamada aceita.");
						if (audioRef.current) {
							audioRef.current.pause();
							audioRef.current.currentTime = 0;
						}
						break;

					case 'reject':
						console.log("[SIGNALING EVENT] - Chamada rejeitada.");
						handleEndCall(true);
						break;

					case 'terminate':
						console.log("[SIGNALING EVENT] - Chamada terminada.");
						handleEndCall(true);

						// Pega o callId diretamente do evento
						const callId = eventData.attrs['call-id'];

						// Envia requisição para o backend salvar o áudio
						await saveCallAudio(callId, ticket);
						break;

					default:
						break;
				}
			});

			setWhatsappInstance(connectedInstance);
			return connectedInstance;
		} catch (error) {
			console.error("Erro ao inicializar a instância do Wavoip:", error);
		}
	};

	// Função para enviar o callId ao backend e salvar o áudio
	const saveCallAudio = async (callId, ticket) => {
		console.log('Erro ao salvar o áudio no servidor:', ticket.contact.number)
		console.log('Erro ao salvar o áudio no servidor:', ticket.contact.name)
		try {
			const response = await api.post(`/api/save-audio/${callId}`, {
				wppId: user.whatsappId,
				companyId: user.company.id,
				userName: user.name,
				contactNumber: ticket.contact.number,
				contactName: ticket.contact.name,
				ticketId: ticket.id,
			});
			console.log('Áudio salvo com sucesso no servidor:', response.data.filePath);
		} catch (error) {
			console.error("Erro ao salvar o áudio no servidor:", error);
		}
	};

	const startWavoipCall = async (whatsappId) => {
		let instance = whatsappInstance;
		if (!instance) {
			instance = await initializeInstance(); // Garante que a instância seja criada
		}
		instance.callStart({ whatsappid: whatsappId });
		setIsCalling(true);
		if (audioRef.current) {
			audioRef.current.play(); // Toca o som de chamada
		}
	};

	// Função para finalizar a chamada e resetar os estados
	const handleEndCall = (forceEnd = false) => {
		if (whatsappInstance || forceEnd) {
			if (whatsappInstance) {
				whatsappInstance.endCall();
			}
			setIsCalling(false); // Atualiza o estado da chamada
			if (audioRef.current) {
				// Chamadas de funções encapsuladas em verificações apropriadas 
				audioRef.current.pause();
				audioRef.current.currentTime = 0;  // Reseta o som
			}
		}
	};

	// Função para iniciar ou finalizar chamada com base no estado atual
	const handleWavoipCall = () => {
		const whatsappId = `${ticket.contact.number}@s.whatsapp.net`;
		if (isCalling) {
			handleEndCall(); // Finaliza a chamada
		} else {
			startWavoipCall(whatsappId); // Inicia a chamada
		}
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		try {
			setLoading(true); // Start loading
			await api.put(`/tickets/${ticket.id}`, { status, userId });
			setLoading(false); // Stop loading
		} catch (error) {
			setLoading(false);
			toastError(error);
		}
	};

	const handleUserIconClick = () => {
		setMobileAdditionalInfoOpen(true)
		drawerOpen ? handleDrawerClose() : handleDrawerOpen();
	};

	const theme = useTheme();

	useEffect(() => {
		let timer;
		if (isCalling) {
			timer = setInterval(() => {
				setCallDuration(prevDuration => prevDuration + 1);
			}, 1000);
		} else {
			setCallDuration(0);
		}
		return () => clearInterval(timer);
	}, [isCalling]);

	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<audio ref={audioRef} loop>
						<source src={callingSound} type="audio/mpeg" />
						Seu navegador não suporta a tag de áudio.
					</audio>

					{/* Ícone de vídeo para enviar link da reunião */}
				

					<Tooltip title="Fechar">
						<IconButton
							onClick={() => setMobileUserOpen(false)}
							className={`${classes.userButton} cmp-mobile-button`}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>

					<Tooltip title="Informações do Contato">
						<IconButton
							onClick={handleUserIconClick}
							style={{ background: drawerOpen ? theme.palette.primary.main : "transparent", color: drawerOpen ? "white" : theme.palette.primary.mainReverseByWhite }}
							className={`${classes.userButton} cmp-desktop-button`}
						>
							<PersonIcon />
						</IconButton>
					</Tooltip>
				

					{ticket.whatsapp?.type === null && ticket.contact.number && isCallsEnabled && (
						<Tooltip title={isCalling ? "Finalizar chamada" : "Realizar chamada"}>
							<IconButton
								onClick={handleWavoipCall}
								className={`${classes.iconButton} cmp-call`}
								color={isCalling ? "secondary" : "primary"}
								style={{
									fontSize: '14.4px',
									backgroundColor: isCalling ? '#FF6F61' : 'initial', // Altera a cor para vermelho
									color: 'white', // Cor do texto, pode ser ajustado
									width: isCalling ? "80px" : "25px"
								}}
							>
								{isCalling ? <CallEnd style={{ color: theme.palette.primary.mainReverseByWhite }} /> : <Phone style={{ color: theme.palette.primary.mainReverseByWhite }} />}
								{isCalling && <div>{`${callDuration}s`}</div>}
							</IconButton>
						</Tooltip>
					)}
						<Tooltip title="Enviar link da reunião">
						<IconButton
							onClick={handleSendMeet}
							className={classes.iconButton}
						>
							<VideocamIcon style={{ color: theme.palette.primary.mainReverseByWhite }} />
						</IconButton>
					</Tooltip>

					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton
							onClick={e => handleUpdateTicketStatus(e, "pending", null)}
							className={classes.iconButton}
							style={{ fontSize: '14.4px' }}
						>
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>

					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtonsCustom;
