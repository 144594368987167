import Routes from "./routes";
import useSettings from "./hooks/useSettings";
import faviconFlow from './assets/favicons/faviconConnect.ico';
import favicon from './assets/favicons/favicon.ico';
import faviconPurple from './assets/favicons/faviconp.ico';
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { WavoipProvider } from "./context/wavoip/WavoipContext"; // Importar o WavoipProvider
import { AuthProvider } from "./context/Auth/AuthContext"; // Importar o AuthProvider corretamente

const faviconTheme = {
    "tarifando": favicon,
    "purple": faviconPurple,
    "connect": faviconFlow
}

const defaultLogoLight = "assets/vector/logo.svg";
const defaultLogoDark = "assets/vector/logo-dark.svg";

const queryClient = new QueryClient();
const App = () => {
    const location = useLocation();
    const [locale, setLocale] = useState();
    
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = (localStorage.getItem("preferredTheme") && localStorage.getItem("preferredTheme").length > 0 && localStorage.getItem("preferredTheme") !== "null") ? localStorage.getItem("preferredTheme") : "light";
    const faviconChosen = faviconTheme[process.env.REACT_APP_THEME_LOGO];

    const [primaryColorLight, setPrimaryColorLight] = useState("#F3F3F3");
    const [primaryColorDark, setPrimaryColorDark] = useState("#333333");
    const [appLogoLight, setAppLogoLight] = useState("");
    const [appLogoDark, setAppLogoDark] = useState("");
    const [appLogoFavicon, setAppLogoFavicon] = useState("");
    const [appName, setAppName] = useState("");
    const Wavoip = require("wavoip-api");
    const [chatlistLight, setChatlistLight] = useState("#FFFFFF");
    const [chatlistDark, setChatlistDark] = useState("#999999");
    const [boxRightLight, setBoxRightLight] = useState("#39ACE7");
    const [boxRightDark, setBoxRightDark] = useState("#39ACE7");
    const [boxLeftLight, setBoxLeftLight] = useState("#39ACE7");
    const [boxLeftDark, setBoxLeftDark] = useState("#39ACE7");
    const isAuthRoute = ["/login", "/signup", "/recovery-password"].includes(location.pathname);
    
    const [mode, setMode] = useState(!preferredTheme ? "light" : preferredTheme);  

    const { getPublicSetting } = useSettings();

    const updateFavicon = (faviconPath) => {
        const link = document.querySelector("#favicon-link");
        if (!link) {
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = faviconPath;
            document.head.appendChild(newLink);
        } else {
            link.href = faviconPath;
        }
    };

    useEffect(() => {
        const themeFavicon = faviconChosen;
        updateFavicon(themeFavicon);
    }, []);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },

            setPrimaryColorLight: (color) => {
                if (!color || !color.startsWith("#")) {
                    color = "#0000FF";
                }
                setPrimaryColorLight(color);
            },
            setPrimaryColorDark: (color) => {
                setPrimaryColorDark(color);
            },
            setAppLogoLight: (file) => {
                setAppLogoLight(file);
            },
            setAppLogoDark: (file) => {
                setAppLogoDark(file);
            },
            setAppLogoFavicon: (file) => {
                setAppLogoFavicon(file);
            },
            setAppName: (name) => {
                setAppName(name);
            },
            setChatlistLight: (color) => {
                setChatlistLight(color);
            },
            setChatlistDark: (color) => {
                setChatlistDark(color);
            },
            setBoxLeftLight: (color) => {
                setBoxLeftLight(color);
            },
            setBoxLeftDark: (color) => {
                setBoxLeftDark(color);
            },
            setBoxRightLight: (color) => {
                setBoxRightLight(color);
            },
            setBoxRightDark: (color) => {
                setBoxRightDark(color);
            }

        }),
        []
    );

    const theme = createTheme(
        {
            // scrollbarStyles: {
            //     "&::-webkit-scrollbar": {
            //         width: '8px',
            //         height: '8px',
            //     },
            //     "&::-webkit-scrollbar-thumb": {
            //         boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            //         backgroundColor: "#00BFFF",
            //     },
            // },
            // scrollbarStylesSoft: {
            //     "&::-webkit-scrollbar": {
            //         width: "8px",
            //     },
            //     "&::-webkit-scrollbar-thumb": {
            //         backgroundColor: mode === "light" ? primaryColorLight : primaryColorDark,
            //     },
            // },

            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                    borderRadius: "8px",
                    transition: ".3s", // Transição para o scrollbar
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'none',
                    backgroundColor: mode === "light" ? `rgba(${primaryColorLight}, 0)` : `rgba(${primaryColorDark}, 0)`, // Ocultar inicialmente
                    borderRadius: "8px",
                    transition: "background-color 0.3s ease, box-shadow 0.3s ease", // Transição para o thumb
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: mode === "light" ? `rgba(${primaryColorLight}, 1)` : `rgba(${primaryColorDark}, 1)`, // Mostrar scrollbar ao passar o mouse
                },
            },
            
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "##FC9C33" : "#fff !important",
                    borderRadius: "8px",
                },
            },



            palette: {
                type: mode,
                whiteOrOrangeGradient: mode === "light" ? "white" : "linear-gradient(90deg, #FFA500, #FF7F00)",
                orangeOrGray: mode === "light" ? "linear-gradient(90deg, #FFA500, #FF7F00)" : "gray",
                whiteOrGray: mode === "light" ? "white" : "gray",
                blackOrWhite: mode === "light" ? "black" : "white",
                primary: {
                    main: mode === "light" ? process.env.REACT_APP_LIGHT_MAIN_COLOR : process.env.REACT_APP_DARK_MAIN_COLOR,
                    second: mode === "light" ? process.env.REACT_APP_LIGHT_SECOND_COLOR : process.env.REACT_APP_DARK_SECOND_COLOR,
                    third: mode === "light" ? process.env.REACT_APP_LIGHT_THIRD_COLOR : process.env.REACT_APP_DARK_THIRD_COLOR,
                    text: mode === "light" ? process.env.REACT_APP_LIGHT_TEXT_COLOR : process.env.REACT_APP_DARK_TEXT_COLOR,
                    links: mode === "light" ? process.env.REACT_APP_LIGHT_LINKS_COLOR : process.env.REACT_APP_DARK_LINKS_COLOR,
                    mainGradient: mode === "light" ? process.env.REACT_APP_LIGHT_MAIN_GRADIENT : process.env.REACT_APP_DARK_MAIN_GRADIENT,
                    secondGradient: mode === "light" ? process.env.REACT_APP_LIGHT_SECOND_GRADIENT : process.env.REACT_APP_DARK_SECOND_GRADIENT,
                    reverseWhiteByMain: mode === "light" ? process.env.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN : process.env.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN,
                    mainReverseByWhite: mode === "light" ? process.env.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE : process.env.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE,
                    whiteGradient: mode === "light" ? 
  "linear-gradient(to right, white, white)" : 
  "linear-gradient(to right, #262525, #4a4848)",
                }, 
                
                second: mode === "light" ? "#FF7F00" : "white",
                tertiary: mode === "light" ? process.env.REACT_APP_LIGHT_THIRD_COLOR : process.env.REACT_APP_DARK_THIRD_COLOR,
                textPrimary: mode === "light" ? "#FC9C33" : "#FFFFFF",
                borderPrimary: mode === "light" ? "#FC9C33F" : "#FFFFFF",
                dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#333",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
                options: mode === "light" ? "#fafafa" : "#666",
                fontecor: mode === "light" ? "#000" : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#333",
                bordabox: mode === "light" ? "#eee" : "#333",
                newmessagebox: mode === "light" ? "#eee" : "#333",
                inputdigita: mode === "light" ? "#fff" : "#333",
                contactdrawer: mode === "light" ? "#fff" : "#333",
                announcements: mode === "light" ? "#ededed" : "#333",
                login: mode === "light" ? "#fff" : "#1C1C1C",
                announcementspopover: mode === "light" ? "#fff" : "#333",
                chatlist: mode === "light" ? "#eee" : "#333",
                boxlist: mode === "light" ? "#ededed" : "#333",
                boxchatlist: mode === "light" ? "#ededed" : "#333",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#333",
                barraSuperior: mode === "light" ? "linear-gradient(to right, #ffb710, #ffb710, #ffb710)" : "#666",
                boxticket: mode === "light" ? "#EEE" : "#333",
                campaigntab: mode === "light" ? "#ededed" : "#333",
                drawerIcons: mode === "light" ? "#ffb710" : "inherit",
                drawerBackground: mode === "light" ? "##fff" : "#333",
                drawerText: mode === "light" ? "#000" : "#fff",

                // background: '#10175b',
                // color: 'white',

            },
            mode,
            appLogoDark,
            appLogoLight,
            appLogoFavicon,
            appName,
            chatlistLight,
            chatlistDark,
            boxLeftLight,
            boxLeftDark,
            boxRightLight,
            boxRightDark,
            calculatedLogoDark: () => {
                if (appLogoDark === defaultLogoDark && appLogoLight !== defaultLogoLight) {
                    return appLogoLight;
                }
                return appLogoDark;
            },
            calculatedLogoLight: () => {
                if (appLogoDark !== defaultLogoDark && appLogoLight === defaultLogoLight) {
                    return appLogoDark;
                }
                return appLogoLight;
            }
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
        i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
        const PAGE_TAB_TITLE = {
            "connect": "Connect Flow",
            "tarifando": "Tarifando Telecon"
        }
        document.title = PAGE_TAB_TITLE[process.env.REACT_APP_THEME_LOGO];
        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);
    
    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode ? mode : "light");
    }, [mode]);

    useEffect(() => {
        if (!isAuthRoute) {
            getPublicSetting("primaryColorLight")
                .then((color) => { setPrimaryColorLight(color || "#F3F3F3") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("primaryColorDark")
                .then((color) => { setPrimaryColorDark(color || "#333333") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("appLogoLight")
                .then((file) => { setAppLogoLight(file || "defaultLogoLight") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("appLogoDark")
                .then((file) => { setAppLogoDark(file || "defaultLogoDark") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("chatlistLight")
                .then((color) => { setChatlistLight(color || "#FFFFFF") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("chatlistDark")
                .then((color) => { setChatlistDark(color || "#999999") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("boxRightLight")
                .then((color) => { setBoxRightLight(color || "#39ACE7") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("boxRightDark")
                .then((color) => { setBoxRightDark(color || "#39ACE7") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("boxLeftLight")
                .then((color) => { setBoxLeftLight(color || "#39ACE7") })
                .catch((error) => { console.log("Error reading setting", error); });

            getPublicSetting("boxLeftDark")
                .then((color) => { setBoxLeftDark(color || "#39ACE7") })
                .catch((error) => { console.log("Error reading setting", error); });
        }
    }, [getPublicSetting, isAuthRoute]);
    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    {/* Ensure AuthContext wraps around components that need user data */}
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
