import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: "8px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    "& th": {
      fontFamily: "Poppins",
      fontWeigth: "500",
      color: theme.palette.primary.mainReverseByWhite
    },
    ...theme.scrollbarStyles,
  },
  buttonStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "4px",
    padding: "0",
    width: "35px",
    background: "transparent",
    height: "35px",
    color: theme.palette.primary.mainReverseByWhite,
    minWidth: "auto",
    "& svg": {
      fontSize: "24px"
    },

    "& span span": {
      display: "none"
    }
  }
}));

export default useStyles;