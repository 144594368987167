import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Box, Grid, LinearProgress, Typography } from "@material-ui/core";
import api from "../../services/api";
import { has, get, isNull } from "lodash";
import CardCounter from "../../components/Dashboard/CardCounter";
import GroupIcon from "@material-ui/icons/Group";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useDate } from "../../hooks/useDate";
import { socketConnection } from "../../services/socket";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: "1rem",
    ...theme.scrollbarStyles,
    border: "none",
    background: "transparent"
  },
  textRight: {
    textAlign: "right",
  },
  tabPanelsContainer: {
    display: "flex",
    flexDirection: "column",
  },

  titleStyles: {
    fontWeight: "bold",
    fontSize: "12px",
    marginBottom: "1rem"
  },

  boxItemStyles: {
    background: theme.palette.primary.secondGradient,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Poppins"
  }
}));

const CampaignReport = () => {
  const classes = useStyles();
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});
  const [validContacts, setValidContacts] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [confirmationRequested, setConfirmationRequested] = useState(0);
  const [confirmed, setConfirmed] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);
  const { datetimeToClient } = useDate();
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    if (mounted.current) {
      findCampaign();
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current && has(campaign, "shipping")) {
      console.log("Campaign data:", campaign);

      if (has(campaign, "contactList")) {
        const contactList = get(campaign, "contactList", { contacts: [] });
        console.log("Contact list:", contactList);
        const valids = contactList.contacts.filter((c) => c.isWhatsappValid);
        console.log("Valid contacts:", valids);
        setValidContacts(valids.length);
      }

      if (has(campaign, "shipping")) {
        const contacts = get(campaign, "shipping", []);
        console.log("Shipping contacts:", contacts);
        const delivered = contacts.filter((c) => !isNull(c.deliveredAt));
        const confirmationRequested = contacts.filter(
          (c) => !isNull(c.confirmationRequestedAt)
        );
        const confirmed = contacts.filter(
          (c) => !isNull(c.deliveredAt) && !isNull(c.confirmationRequestedAt)
        );
        console.log("Delivered:", delivered);
        console.log("Confirmation requested:", confirmationRequested);
        console.log("Confirmed:", confirmed);
        setDelivered(delivered.length);
        setConfirmationRequested(confirmationRequested.length);
        setConfirmed(confirmed.length);
      }
    }
  }, [campaign]);

  useEffect(() => {
    console.log("Calculating percent:", delivered, validContacts);
    setPercent(validContacts > 0 ? (delivered / validContacts) * 100 : 0);
  }, [delivered, validContacts]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.record.id === +campaignId) {
        setCampaign(data.record);
        if (data.record.status === "FINALIZADA") {
          setTimeout(() => {
            findCampaign();
          }, 5000);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [campaignId]);

  const findCampaign = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/campaigns/${campaignId}`);
      console.log("API campaign data:", data);
      setCampaign(data);
    } catch (error) {
      console.error("Error fetching campaign:", error);
    }
    setLoading(false);
  };

  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return "Inativa";
      case "PROGRAMADA":
        return "Programada";
      case "EM_ANDAMENTO":
        return "Em Andamento";
      case "CANCELADA":
        return "Cancelada";
      case "FINALIZADA":
        return "Finalizada";
      default:
        return val;
    }
  };

  return (
    <MainContainer>
      <MainHeader>
        <Grid style={{ display:"flex", justifyContent: "space-between", flexWrap: "nowrap", alignItems: "center" }} container>
          <Grid xs={12} item>
            <Title>Relatório da {campaign.name || "Campanha"}</Title>
          </Grid>
    <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Button
        variant="contained"
        style={{
          padding: "0",
          border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
          color: theme.palette.primary.mainReverseByWhite,
          fontWeight: "bold",
          background: "transparent",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: "25px",
          height: "25px",
          minWidth: "25px",
        }}
        onClick={() => history.push("/campaigns")}
      >
        <ArrowBackIcon style={{ margin: "0", padding: "0", fontSize: "1rem" }} /> {/* Tamanho do ícone ajustado */}
      </Button>
    </Box>
        </Grid>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Grid container spacing={3} style={{ display: "flex", flexDirection: "column" }}>
          <Box style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap: "1rem" }}>
            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <GroupIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                  <div>
                    <Typography className={classes.titleStyles}>Contatos Válidos</Typography>
                    <Typography variant="span">{loading ? "Carregando..." : validContacts}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            {campaign.confirmation && (
              <>
                <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                  <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DoneIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                      <div>
                        <Typography className={classes.titleStyles}>Confirmações Solicitadas</Typography>
                        <Typography variant="span">{loading ? "Carregando..." : confirmationRequested}</Typography>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                  <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DoneAllIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                      <div>
                        <Typography className={classes.titleStyles}>Confirmações</Typography>
                        <Typography variant="span">{loading ? "Carregando..." : confirmed}</Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
              </>
            )}

            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                  <div>
                    <Typography className={classes.titleStyles}>Entregues</Typography>
                    <Typography variant="span">{loading ? "Carregando..." : delivered}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            {campaign.whatsappId && (
              <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WhatsAppIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                    <div>
                      <Typography className={classes.titleStyles}>Conexão</Typography>
                      <Typography variant="span">{loading ? "Carregando..." : campaign.whatsapp.name}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {campaign.contactListId && (
              <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
                <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ListAltIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                    <div>
                      <Typography className={classes.titleStyles}>Lista de Contatos</Typography>
                      <Typography variant="span">{loading ? "Carregando..." : campaign.contactList.name}</Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ScheduleIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                  <div>
                    <Typography className={classes.titleStyles}>Agendamento</Typography>
                    <Typography variant="span">{loading ? "Carregando..." : datetimeToClient(campaign.scheduledAt)}</Typography>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item style={{ flexBasis: "calc(33.333% - 1rem)" }}>
              <div className={classes.boxItemStyles} style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EventAvailableIcon fontSize="inherit" style={{ marginRight: '8px', fontSize: '2rem' }} />
                  <div>
                    <Typography className={classes.titleStyles}>Conclusão</Typography>
                    <Typography variant="span">{loading ? "Carregando..." : datetimeToClient(campaign.completedAt)}</Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h6" component="h2" style={{ marginTop: "11rem", color: "lightgreen", fontFamily: "Poppins", fontWeight: "bold", textAlign: "center" }}>
              Status: {formatStatus(campaign.status)} {delivered} de {validContacts}
            </Typography>
            <Grid xs={12} item>
              <LinearProgress
                variant="determinate"
                style={{ height: 15, borderRadius: 100, margin: "20px 0" }}
                value={percent}
              />
            </Grid>
          </Box>
        </Grid>

      </Paper>
    </MainContainer>
  );
};

export default CampaignReport;
