import {
  makeStyles,
} from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "100vh",
      backgroundColor: theme.palette.fancyBackground,
      '& .MuiButton-outlinedPrimary': {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
        border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: theme.mode === 'light' ? '#000000' : '#FFF',
      }
    },
    avatar: {
      width: "100%",
    },
    toolbar: {
      color: theme.palette.dark.main,
      background: theme.palette.mainGradient,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      minHeight: "48px",
      [theme.breakpoints.down("sm")]: {
        height: "48px"
      }
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `100%`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
      }
    },
    menuButton: {
      marginRight: 6,
    },
    menuButtonHidden: {
      display: "block",
    },
    title: {
      flexGrow: 1,
      fontSize: 14,
      color: "white",
    },
    drawerPaper: {
      background: theme.palette.drawerBackground,
      color: theme.palette.drawerText,
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      ...theme.scrollbarStylesSoft,

    },
    iconDrawer: {
      color: theme.palette.drawerIcons,
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
      background: theme.palette.primary.whiteGradient
    },
    appBarSpacer: {
      minHeight: "48px",
    },
    content: {
      flex: 1,
      overflow: "auto",
  
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column"
    },
    containerWithScroll: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      overflowX: "hidden",
      ...theme.scrollbarStyles,
    },
    NotificationsPopOver: {
      // color: theme.barraSuperior.secondary.main,
    },
    logo: {
      // width: "80%",
      height: "auto",
      maxHeight: 43,
      maxWidth: 180,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        height: "80%",
        maxWidth: 180,
      },
      logo: theme.logo
    },
  }));

  export default useStyles;