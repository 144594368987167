import React from "react";
import SliderComponent from "react-slick"; // Importando o carrossel
import { makeStyles } from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import socialMedia from "../../assets/social-media-removebg-preview.png";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    width: '400px',
  },
  carousel: {
    width: '100%',
    height: '300px',
  },
  carouselItem: {
    width: '100%',
    height: '300px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: 'white',
    padding: '10px',
    backgroundRepeat: "no-repeat"
  },
  title: {
    color: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.primary.third,
  }
}));

const InfoSlider = () => {
  const classes = useStyles();

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
        <div className={`cmp-container__slider-container ${classes.sliderContainer}`}>
            <SliderComponent {...carouselSettings} className={classes.carousel}>
            <div className={`cmp-login__slider`}>
                <div className={classes.carouselItem} style={{
                backgroundImage: `url(${socialMedia})`,
                width: "300px",
                height: "400px",
                backgroundSize: 'cover',
                }}></div>
                <h3 className={classes.title}>Vantagem do sistema multicanal</h3>
                <p className={classes.text}>Transforme o atendimento pelo WhatsApp em um processo fluido e eficiente. Com a Connectflow, todas as conversas ficam centralizadas, facilitando o acompanhamento e a gestão das interações. Responda rapidamente e ofereça um atendimento personalizado que fideliza clientes e otimiza o fluxo de trabalho da sua equipe.</p>
            </div>
            <div className={`cmp-login__slider`}>
                <div className={classes.carouselItem} style={{
                backgroundImage: `url("https://www.cidademarketing.com.br/marketing/wp-content/uploads/2018/12/whatsapp-640x640.png")`,
                }}></div>
                <h3 className={classes.title}>WhatsApp</h3>
                <p className={classes.text}>Transforme o atendimento pelo WhatsApp em um processo fluido e eficiente. Com a Connectflow, todas as conversas ficam centralizadas, facilitando o acompanhamento e a gestão das interações. Responda rapidamente e ofereça um atendimento personalizado que fideliza clientes e otimiza o fluxo de trabalho da sua equipe.</p>
            </div>
            <div className={`cmp-login__slider`}>
                <div className={classes.carouselItem} style={{
                backgroundImage: `url("https://cdn-icons-png.flaticon.com/512/5968/5968764.png")`,
                }}></div>
                <h3 className={classes.title}>Facebook</h3>
                <p className={classes.text}>Conecte sua página do Facebook ao sistema multicanal da Connectflow. Organize e gerencie mensagens e interações em um só lugar, garantindo respostas rápidas e consistentes. Nossa solução permite que você acompanhe cada interação em tempo real, oferecendo um atendimento mais personalizado e eficiente.</p>
            </div>
            <div className={`cmp-login__slider`}>
                <div className={classes.carouselItem} style={{
                backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/5/58/Instagram-Icon.png")`,
                }}></div>
                <h3 className={classes.title}>Instagram</h3>
                <p className={classes.text}>Centralize todas as mensagens do Instagram em um único lugar. Com a Connectflow, você gerencia comentários e directs de forma ágil e eficiente, garantindo respostas rápidas. Nossa plataforma facilita o atendimento ao cliente, permitindo maior controle sobre suas interações e aumentando a produtividade da sua equipe.</p>
            </div>
            </SliderComponent>
        </div>
    </>
  );
};

export default InfoSlider;
