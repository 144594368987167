import React, { useEffect, useState } from "react"; 
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";
import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";

const QrcodeModal = ({ open, onClose, whatsAppId, setUpdatedWhatsApps, setQrModalOpen, setSelectedWhatsApp }) => {
  const [qrCode, setQrCode] = useState("");
  const [checkIntervalId, setCheckIntervalId] = useState(null);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
        
        setUpdatedWhatsApps(prevWhatsApps => 
          prevWhatsApps.map(whatsApp =>
            whatsApp.id === whatsAppId
              ? { ...whatsApp, status: data.session.status, qrcode: data.session.qrcode }
              : whatsApp
          )
        );
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose(setQrModalOpen, setSelectedWhatsApp);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose, setUpdatedWhatsApps]);

  useEffect(() => {
    if (!whatsAppId || closed) return;

    const checkWhatsAppSession = async () => {
      try {
        const response = await api.get(`/whatsapp/${whatsAppId}`);
        if (response.data.status === "CONNECTED") {
          setUpdatedWhatsApps(prevWhatsApps => 
            prevWhatsApps.map(whatsApp =>
              whatsApp.id === whatsAppId
                ? { ...whatsApp, status: "CONNECTED" }
                : whatsApp
            )
          );

          clearInterval(checkIntervalId);
          onClose(setQrModalOpen, setSelectedWhatsApp); 
        }
      } catch (err) {
        toastError(err);
      }
    };

    const intervalId = setInterval(checkWhatsAppSession, 20000); 
    setCheckIntervalId(intervalId);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      toastError("Não conseguimos estabelecer a conexão. Tente novamente.");
      onClose(setQrModalOpen, setSelectedWhatsApp); 
    }, 80000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
      if (!closed) {
        setClosed(true);
        checkWhatsAppSession(); // Certifique-se de chamar a função aqui
      }
    };
  }, [whatsAppId, onClose, setUpdatedWhatsApps, closed]);

  return (
    <Dialog open={open} onClose={() => onClose(setQrModalOpen, setSelectedWhatsApp)} maxWidth="lg" scroll="paper">
      <DialogContent>
        <Paper elevation={0}>
          <Typography color="primary" gutterBottom>
            {i18n.t("qrCode.message")}
          </Typography>
          {qrCode ? (
            <QRCode value={qrCode} size={256} />
          ) : (
            <span>Waiting for QR Code</span>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
