import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import * as XLSX from "xlsx";
import reducer from "./ContactsComponents/reducer";
import useStyles from "./ContactsComponents/styles";
import Modals from "./ContactsComponents/components/Modals";
import ContactsTable from "./ContactsComponents/components/ContactsTable";
import ContactsHeader from "./ContactsComponents/components/ContactsHeader";
import { useMediaQuery } from "@material-ui/core";
import ContactsCards from "./ContactsComponents/components/ContactsCards";

const Contacts = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [importContactModalOpen, setImportContactModalOpen] = useState(false);
  const [hideNum, setHideNum] = useState(false);
  const [userSelectModalOpen, setUserSelectModalOpen] = useState(false);
  const [selectedContactIdForUser, setSelectedContactIdForUser] = useState(null);

  // Função para abrir o modal de seleção de usuário
  const handleOpenUserSelectModal = (contactId) => {
    setSelectedContactIdForUser(contactId);
    setUserSelectModalOpen(true);
  };

  const handleCloseUserSelectModal = () => {
    setUserSelectModalOpen(false);
    setSelectedContactIdForUser(null);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false); // Colocar loading como false no final
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket && ticket.uuid) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleImportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadModelToExport = () => {
    const allDatas = [
      { name: "Contato 1", number: "5511999999999", email: "email@email.com" },
      { name: "Contato 2", number: "5511999999999", email: "email@email.com" },
    ];

    const exportData = allDatas.map((e) => ({
      name: e.name,
      number: e.number,
      email: e.email,
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, "Contatos");
    XLSX.writeFile(wb, "exemplo_upload.xlsx");

    handleClose();
  };

  const handleOpenImportModal = () => {
    setImportContactModalOpen(true);
    handleClose();
  };

  const handleReload = () => {
    dispatch({ type: "RESET" });
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <Modals
        setImportContactModalOpen={setImportContactModalOpen}
        userSelectModalOpen={userSelectModalOpen}
        selectedContactIdForUser={selectedContactIdForUser}
        handleCloseUserSelectModal={handleCloseUserSelectModal}
        newTicketModalOpen={newTicketModalOpen}
        contactTicket={contactTicket}
        handleCloseOrOpenTicket={handleCloseOrOpenTicket}
        importContactModalOpen={importContactModalOpen}
        hideNum={hideNum}
        user={user}
        contactModalOpen={contactModalOpen}
        handleCloseContactModal={handleCloseContactModal}
        selectedContactId={selectedContactId}
        confirmOpen={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        deletingContact={deletingContact}
        handleDeleteContact={handleDeleteContact}
        handleImportContact={handleImportContact}
      />

      <ContactsHeader
        classes={classes}
        searchParam={searchParam}
        handleSearch={handleSearch}
        handleClick={handleClick}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        setConfirmOpen={setConfirmOpen}
        handleOpenImportModal={handleOpenImportModal}
        contacts={contacts}
        handleDownloadModelToExport={handleDownloadModelToExport}
        handleOpenContactModal={handleOpenContactModal}
        handleReload={handleReload}
      />

  {isMobile ? (
        <ContactsCards
          contacts={contacts}
          loading={loading}
          user={user}
          classes={classes}
          handleOpenUserSelectModal={handleOpenUserSelectModal}
          setContactTicket={setContactTicket}
          setNewTicketModalOpen={setNewTicketModalOpen}
          hadleEditContact={handleEditContact}
          setConfirmOpen={setConfirmOpen}
          setDeletingContact={setDeletingContact}
          handleScroll={handleScroll}
        />
      ) : (
        <ContactsTable
          classes={classes}
          contacts={contacts}
          loading={loading}
          user={user}
          handleOpenUserSelectModal={handleOpenUserSelectModal}
          setContactTicket={setContactTicket}
          setNewTicketModalOpen={setNewTicketModalOpen}
          hadleEditContact={handleEditContact}
          setConfirmOpen={setConfirmOpen}
          setDeletingContact={setDeletingContact}
          handleScroll={handleScroll}
        />
      )}
    </MainContainer>
  );
};

export default Contacts;
