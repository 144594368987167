import { Chip, Paper, TextField, IconButton, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import './TagsContainer.css'; // Importar arquivo CSS
import AddIcon from '@material-ui/icons/Add'; // Importar ícone de adição

export function TagsContainer({ ticket }) {
    const [tags, setTags] = useState([]);
    const [selecteds, setSelecteds] = useState([]);
    const [isInputVisible, setInputVisible] = useState(false); // Estado para controlar a visibilidade do input
    const isMounted = useRef(true);
    const theme = useTheme();
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    setSelecteds(ticket.tags);
                } else {
                    setSelecteds([]);
                }
            });
        }
    }, [ticket]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            setTags(data);
        } catch (err) {
            toastError(err);
        }
    };

    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const onChange = async (value, reason) => {
        let optionsChanged = [];
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item });
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
            await loadTags();
        } else {
            optionsChanged = value;
        }
        setSelecteds(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: optionsChanged });
    };

    const handleDelete = (tagToDelete) => {
        setSelecteds((prevSelecteds) =>
            prevSelecteds.filter((tag) => tag.name !== tagToDelete.name)
        );
        syncTags({ ticketId: ticket.id, tags: selecteds.filter(tag => tag.name !== tagToDelete.name) });
    };

    return (
        <Paper style={{ padding: "1rem 0 0 0", boxShadow: "none" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: "none" }}>
                <h3 style={{ color: theme.palette.primary.mainReverseByWhite, fontFamily: 'Poppins' }}>Tags</h3>
                <IconButton 
                    onClick={() => setInputVisible(!isInputVisible)}
                    style={{
                        color: theme.palette.primary.mainReverseByWhite, // Cor do ícone e da borda
                        border: '2px solid ' + theme.palette.primary.mainReverseByWhite, // Borda laranja
                        borderRadius: '4px', // Bordas arredondadas
                        width: '25px', // Largura do botão
                        height: '25px', // Altura do botão
                        padding: 0, // Remove padding extra
                    }}
                >
                    <AddIcon style={{ fontSize: 18 }} /> {/* Ajuste do tamanho do ícone */}
                </IconButton>
            </div>
            <div style={{
                display: isInputVisible ? 'block' : 'none', // Esconde completamente o input quando não visível
                marginTop: isInputVisible ? 8 : 0, // Adiciona margem apenas quando o input está visível
            }}>
                <Autocomplete
                    multiple
                    size="small"
                    options={tags} // Mantenha as opções vazias para não exibir sugestões
                    value={selecteds}
                    freeSolo
                    onChange={(e, v, r) => onChange(v, r)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Adicionar Tags" />
                    )}
                    renderTags={() => null} // Impede a renderização das tags no input
                />
            </div>
            <div className="tags-scroll-container" style={{
                display: 'flex',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                boxShadow: "none",
                margin: "1rem 0"
            }}>
                {selecteds.map((option, index) => (
                    <Chip
                        key={index}
                        variant="outlined"
                        style={{
                            border: `2px solid ${option.color || '#eee'}`,
                            backgroundColor: 'transparent',
                            color: option.color || '#eee',
                            marginRight: 4,
                            marginBottom: 4,
                            fontWeight: 600,
                            borderRadius: 3,
                            fontSize: "0.8em",
                            whiteSpace: "nowrap"
                        }}
                        label={option.name.toUpperCase()}
                        onDelete={() => handleDelete(option)} // Adiciona o evento de exclusão
                    />
                ))}
            </div>
        </Paper>
    );
}
