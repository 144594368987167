import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    IconButton,
    Grid,
    Paper,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import baileyIcon from "../../../../assets/baileys-icon.png";
import { i18n } from "../../../../translate/i18n";
import { Can } from "../../../../components/Can";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const ContactsCards = ({
    classes,
    contacts,
    loading,
    user,
    handleOpenUserSelectModal,
    setContactTicket,
    setNewTicketModalOpen,
    hadleEditContact,
    setConfirmOpen,
    setDeletingContact,
    handleScroll,
}) => {
    return (
        <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
            style={{ padding: '16px' }}
        >
            <Grid container spacing={2}>
                {contacts.map((contact) => (
                    <Grid item xs={12} sm={6} md={4} key={contact.id}>
                        <Card>
                            <CardMedia>
                                <div style={{ position: 'relative', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => {
                                        setContactTicket(contact);
                                        setNewTicketModalOpen(true);
                                    }}>
                                        <Avatar
                                            style={{
                                                width: "112px", // Aumento de 40%
                                                height: "112px", // Aumento de 40%
                                                borderRadius: "8px",
                                            }}
                                            src={contact.profilePicUrl}
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: "0",
                                                right: "0",
                                                width: "24px", // Ajuste o tamanho do ícone
                                                height: "24px", // Ajuste o tamanho do ícone
                                                borderRadius: "8px",
                                                background: contact.number && (contact.whatsapp?.type === null || contact.whatsapp?.type === "official")
                                                    ? "#25D366"  // Cor do WhatsApp
                                                    : contact.messengerId
                                                        ? "#3b5998" // Cor do Facebook
                                                        : contact.instagramId
                                                            ? "linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)" // Cor do Instagram
                                                            : "transparent",
                                                display: 'flex', // Centraliza o conteúdo do ícone
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {contact.number && contact.whatsapp?.type === null && (
                                                <img style={{ width: "16px", height: "16px" }} src={baileyIcon} alt="WhatsApp Official" />
                                            )}
                                            {contact.whatsapp?.type === "official" && (
                                                <WhatsAppIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}
                                            {contact.messengerId && (
                                                <FacebookIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}
                                            {contact.instagramId && (
                                                <InstagramIcon style={{ color: "white", fontSize: "16px" }} />
                                            )}
                                            {!(contact.number && (contact.whatsapp?.type === null || contact.whatsapp?.type === "official") || contact.messengerId || contact.instagramId) && (
                                                <img style={{ width: "16px", height: "16px" }} src={baileyIcon} alt="Bailey Icon" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h6">{contact.name}</Typography>
                                <Typography variant="body2" color="textSecondary">{contact.number}</Typography>
                                <Typography variant="body2" color="textSecondary">{contact.email}</Typography>
                            </CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                                {user.profile === 'admin' && (
                                    <IconButton size="small" onClick={() => handleOpenUserSelectModal(contact.id)}>
                                        <PersonAddIcon />
                                    </IconButton>
                                )}
                                <IconButton size="small" onClick={() => {
                                    setContactTicket(contact);
                                    setNewTicketModalOpen(true);
                                }}>
                                    <ConfirmationNumberIcon />
                                </IconButton>
                                <IconButton size="small" onClick={() => hadleEditContact(contact.id)}>
                                    <EditIcon />
                                </IconButton>
                                <Can
                                    role={user.profile}
                                    perform="contacts-page:deleteContact"
                                    yes={() => (
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmOpen(true);
                                                setDeletingContact(contact);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    )}
                                />
                            </div>
                        </Card>
                    </Grid>
                ))}
                {loading && <TableRowSkeleton avatar columns={3} />}
            </Grid>
        </Paper>
    );
};

export default ContactsCards;
