import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CachedIcon from "@material-ui/icons/Cached";

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import NotificationsVolume from "../components/NotificationsVolume";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import { messages } from "../translate/languages";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";

import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";

import { useDate } from "../hooks/useDate";

import ColorModeContext from "../layout/themeContext";
import useCompanies from "../hooks/useCompanies";
import NestedMenuItem from "material-ui-nested-menu-item";
import useStyles from "./theme"

const LoggedInLayout = ({ children, themeToggle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const classes = useStyles();
  const { finding } = useCompanies();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const initialMenu = localStorage.getItem("menuOpenDrawer") ? localStorage.getItem("menuOpenDrawer") : "";
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : initialMenu === "open"); 
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const history = useHistory();
  const { colorMode } = useContext(ColorModeContext);
  const [darkMode, setDarkModeOn] = useState(localStorage.getItem("preferredTheme") !== "light");
  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);
  const [companyDueDate, setCompanyDueDate] = useState();

  const { dateToClient } = useDate();

  useEffect(() => {
    if (document.body.offsetWidth < 950) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");


    getDueDate(companyId);

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
      if(data.user.profile === "user") {
        history.push("/tickets")
      }
    });

    if(isMobile) {
      setDrawerOpen(false);
      setDrawerVariant("temporary");
    }
    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if(isMobile) {
      setDrawerOpen(false);
      setDrawerVariant("temporary");
    }
  }, [isMobile])


  const getDueDate = async (companyId) => {
    const companiesList = await finding(companyId);
    setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
  };
  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleLanguageMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setLanguageOpen(true);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
    
    setMenuOpen(false);
  };


  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseProfileMenu();
  };

  const handleClickLogout = () => {
    handleCloseProfileMenu();
    handleLogout();
  };

  const handleChooseLanguage = (language) => {
    localStorage.setItem("language", language);
    window.location.reload(false);
  }

  const drawerClose = () => {
    if (document.body.offsetWidth < 950) {
      setDrawerOpen(false);
    }
  };

  const handleRefreshPage = () => {
    window.location.reload(false);
  }

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
    setMenuOpen(false);
    setDarkModeOn(localStorage.getItem("preferredTheme") === "light")
  }
 
  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={`cmp-menu ${classes.root} ${darkMode ? "cmp-darkmode": ""} ${drawerOpen ? `cmp-menu--menu-open--connections` : ""}`}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? `cmp-menu--menu-open ${classes.drawerPaper}` : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            isMobile ? drawerOpen && classes.drawerPaperClose : !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          {/* <img src={logo} className={classes.logo} alt="logo" /> */}
          <IconButton className={classes.iconDrawer} onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />
          <List className={`cmp-menu__list ${classes.containerWithScroll}`}>
            <MainListItems isMobile={isMobile} drawerClose={drawerClose} collapsed={!drawerOpen} />
          </List>
        <Divider />
      </Drawer>

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color="primary"
      >
        <Toolbar variant="dense" className={`${classes.toolbar} ${drawerOpen ? "cmp-menu__top-header--open" : "cmp-menu__top-header"} `}>
          <IconButton
            edge="start"
            variant="contained"
            aria-label="open drawer"
            onClick={() => {
              localStorage.setItem("menuOpenDrawer", drawerOpen ? "closed" : "open");
              setDrawerOpen(!drawerOpen)
            }}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h2"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >

          </Typography>


          {/* <Typography variant="caption" style={{ color: "white", marginRight: 15, fontWeight: "bold" }}>Sua assinatura: {companyDueDate}</Typography> */}

          <Tooltip arrow title={<Typography variant="caption" style={{ color: "white", marginRight: 15, fontWeight: "bold" }}>Sua assinatura: {companyDueDate}</Typography>}>
            <EventAvailableIcon style={{ color: "white", marginRight: isMobile ? 0 : 15 }} />
          </Tooltip>


          {/* <IconButton edge="start" onClick={toggleColorMode}>
            {theme.mode === 'dark' ? <Brightness7Icon style={{ color: "white" }} /> : <Brightness4Icon style={{ color: "white" }} />}
          </IconButton> */}

          {<NotificationsVolume
            setVolume={setVolume}
            volume={volume}
          />}

          {<IconButton
            onClick={handleRefreshPage}
            aria-label={i18n.t("mainDrawer.appBar.refresh")}
            color="inherit"
          >
            <CachedIcon style={{ color: "white" }} />
          </IconButton>}

          {user.id && <NotificationsPopOver volume={volume} />}

          <AnnouncementsPopover />

          <ChatPopover />

          {/* <div>
            <IconButton
              aria-label="current language"
              aria-controls="menu-language"
              aria-haspopup="true"
              onClick={handleLanguageMenu}
              variant="contained"
              style={{ color: "white" }}
            >
              <LanguageIcon key={currentLanguage} />
            </IconButton>

            <IconButton 
              edge="start" 
              aria-label="WhatsApp-Contact"
              aria-haspopup="true"
              variant="contained"
              onClick={() => openInNewTab(`https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`)}
            >
              <WhatsApp style={{ color: "white" }} />
            </IconButton> 

            <Menu
              id="language-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={languageOpen}
              onClose={handleCloseLanguageMenu}
            >
              {
                Object.keys(messages).map((m) => (
                  <MenuItem key={m} onClick={() => handleChooseLanguage(m)}>
                    {messages[m].translations.mainDrawer.appBar.i18n.language}
                  </MenuItem>
                ))
              }
            </Menu>
          </div> */}

          <div>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleProfileMenu}
              variant="contained"
              style={{ color: "white" }}
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>{i18n.t("mainDrawer.appBar.user.profile")} </MenuItem>
              <MenuItem onClick={toggleColorMode}> {theme.mode === 'dark' ? i18n.t("mainDrawer.appBar.styleLight") : i18n.t("mainDrawer.appBar.styleDark")}  </MenuItem>
              <NestedMenuItem
                label={i18n.t("mainDrawer.appBar.user.language")}
                parentMenuOpen={menuOpen}
              >
                {
                  Object.keys(messages).map((m, index) => (
                    <MenuItem key={`menu-list__${index}`} onClick={() => handleChooseLanguage(m)}>
                      {messages[m].translations.mainDrawer.appBar.i18n.language}
                    </MenuItem>
                  ))
                }
              </NestedMenuItem>
              {/* <MenuItem > {i18n.t("mainDrawer.appBar.language")} </MenuItem> */}
              <MenuItem onClick={handleClickLogout}> {i18n.t("mainDrawer.appBar.user.logout")} </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;