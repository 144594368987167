import React, { useState, useEffect } from "react";
import { Avatar, CardHeader, IconButton, useTheme } from "@material-ui/core"; // Supondo que você esteja usando @material-ui/core
import FileCopyIcon from '@material-ui/icons/FileCopy'; // Importando o ícone de copiar
import CheckIcon from '@material-ui/icons/Check'; // Importando o ícone de check
import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
    const { user } = ticket;
    const [userName, setUserName] = useState('');
    const [contactName, setContactName] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        if (contact) {
            setContactName(contact.name);
            if (document.body.offsetWidth < 950) {
                if (contact.name.length > 10) {
                    const truncatedName = contact.name.substring(0, 10) + '...';
                    setContactName(truncatedName);
                }
            }
        }

        if (user && contact) {
            setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);
            if (document.body.offsetWidth < 950) {
                setUserName(`${user.name}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(`#${ticket.id}`) // Copia o ID do ticket para a área de transferência
            .then(() => {
                setCopySuccess(true); // Define sucesso na cópia
                setTimeout(() => {
                    setCopySuccess(false); // Reverte após 2 segundos
                }, 2000);
            })
            .catch(err => {
                console.error('Erro ao copiar: ', err);
            });
    };
    const theme = useTheme();
    return (
        <CardHeader
            onClick={onClick}
            style={{ cursor: "pointer" }}
            avatar={<Avatar style={{borderRadius: "4px"}} src={contact.profilePicUrl} alt="contact_image" />}
            title={
                <span style={{ fontWeight: 'bold', color: theme.palette.primary.mainReverseByWhite }}>{contactName}</span>
            }
            subheader={
                <span style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={handleCopy} size="small" style={{ marginRight: '4px', padding: '0', color: theme.palette.primary.mainReverseByWhite, transform: 'scale(0.7)' }}>
                        {copySuccess ? <CheckIcon fontSize="inherit" /> : <FileCopyIcon fontSize="inherit" />}
                    </IconButton>
                    <span style={{position: "relative", top: "2px"}}>#{ticket.id}</span>
                </span>
            }
            titleTypographyProps={{ noWrap: true }}
            subheaderTypographyProps={{ noWrap: true }}
        />
    );
};

export default TicketInfo;
