import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Typography,
  Divider,
  Grid,
  TextField,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext'; // Importando o contexto de autenticação

const SaudacaoComponent = () => {
  const [open, setOpen] = useState(false);
  const [mensagem, setMessage] = useState('');
  const [whatsappId, setWhatsappId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [editingId, setEditingId] = useState(null); // Para armazenar o ID da saudação em edição
  const [groups, setGroups] = useState([]);
  const [saudacoes, setSaudacoes] = useState([]); // Estado para armazenar as saudações
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const { whatsApps, loading } = useWhatsApps();
  const { user } = useContext(AuthContext); // Obtendo o user do contexto de autenticação

  const companyId = localStorage.getItem('companyId'); // Obtendo o companyId do localStorage

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMessage('');
    setWhatsappId('');
    setGroupId('');
    setEditingId(null); // Reseta o ID de edição
  };

  // Fetch groups when a WhatsApp connection is selected
  useEffect(() => {
    const fetchGroups = async () => {
      if (whatsappId) {
        try {
          setIsLoadingGroups(true);
          const { data } = await api.get('/api/groups/list-groups', {
            params: { whatsappId }
          });
          setGroups(data.groups);
        } catch (error) {
          toast.error('Erro ao buscar grupos.');
        } finally {
          setIsLoadingGroups(false);
        }
      }
    };

    fetchGroups();
  }, [whatsappId]);

  // Função para buscar as saudações (usada para atualizar a tabela automaticamente)
  const fetchSaudacoes = async () => {
    try {
      const { data } = await api.get('/api/groups/saudacoes', {
        params: { companyId }
      });
  
      if (data.data.length === 0) {
        // Quando não há saudações, pode mostrar um toast ou apenas ignorar
        toast.info('Nenhuma saudação encontrada.');
      } else {
        setSaudacoes(data.data); // Atualiza o estado com as saudações
      }
    } catch (error) {
      // O erro real da API
    }
  };
  

  useEffect(() => {
    if (companyId) {
      fetchSaudacoes();
    }
  }, [companyId]);

  const handleSave = async () => {
    if (!mensagem || (!editingId && (!whatsappId || !groupId))) {
      toast.error('Preencha todos os campos.');
      return;
    }
  
    try {
      if (editingId) {
        // Se estiver editando, faz PUT
        await api.put(`/api/groups/saudacao/${editingId}`, { mensagem, userId: user.id, companyId });
        toast.success('Mensagem de saudação atualizada com sucesso!');
      } else {
        // Se não estiver editando, faz POST
        await api.post('/api/groups/saudacao', { mensagem, whatsappId, groupId, userId: user.id, companyId });
        toast.success('Mensagem de saudação salva com sucesso!');
      }
      handleClose();
      fetchSaudacoes(); // Atualiza a tabela de saudações
    } catch (error) {
      toast.error('Erro ao salvar a saudação.');
    }
  };
  

  const handleEdit = (saudacao) => {
    setEditingId(saudacao.id);
    setMessage(saudacao.mensagem);
    setWhatsappId(saudacao.whatsappId);
    setGroupId(saudacao.groupId);
    handleOpen();
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/groups/saudacao/${id}`);
      toast.success('Saudação deletada com sucesso!');
      fetchSaudacoes(); // Atualiza a tabela de saudações após deletar
    } catch (error) {
      toast.error('Erro ao deletar a saudação.');
    }
  };

  // Formatação da data no fuso horário de Brasília (BRT)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Data Inválida'
      : date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Adicionar Saudação
          </Button>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            background: 'white',
            padding: '30px',
            margin: '4% auto',
            maxWidth: '600px',
            borderRadius: '10px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
            {editingId ? "Editar Saudação" : "Criar Saudação"}
          </Typography>
          <Divider style={{ marginBottom: '1.5rem' }} />

          <TextField
            fullWidth
            label="Mensagem de Saudação"
            value={mensagem}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />

          {editingId ? null : (
            <>
              <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                <InputLabel>WhatsApp ID</InputLabel>
                <Select
                  value={whatsappId}
                  onChange={(e) => setWhatsappId(e.target.value)}
                  label="WhatsApp ID"
                  disabled={loading}
                >
                  {whatsApps.filter((contact) => contact.type === null).map((whatsApp) => (
                    <MenuItem key={whatsApp.id} value={whatsApp.id}>
                      {whatsApp.name || whatsApp.number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                <InputLabel>Grupo</InputLabel>
                <Select
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                  label="Grupo"
                  disabled={isLoadingGroups || groups.length === 0}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.subject}
                    </MenuItem>
                  ))}
                </Select>
                {isLoadingGroups && <CircularProgress size={24} />}
              </FormControl>
            </>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={handleClose}
                fullWidth
                color="secondary"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                fullWidth
              >
                {editingId ? "Atualizar" : "Salvar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Tabela para exibir as saudações */}
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Mensagem</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Criado Por</TableCell>
              <TableCell>Data Criada</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {saudacoes.map((saudacao) => (
              <TableRow key={saudacao.id}>
                <TableCell>{saudacao.id}</TableCell>
                <TableCell>{saudacao.mensagem}</TableCell>
                <TableCell>{saudacao.groupId}</TableCell>
                <TableCell>{saudacao.user ? saudacao.user.name : 'Desconhecido'}</TableCell>
                <TableCell>{formatDate(saudacao.createdAt)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(saudacao)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(saudacao.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SaudacaoComponent;
