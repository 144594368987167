import React from "react";
import { CheckCircle, HourglassEmpty, Schedule } from "@material-ui/icons";
import OnlineAttendants from "../OnlineAttendants";
import DashboardItem from "../DashboardItem";

const DashboardHeader = ({ attendants, counters, classes, serviceQuality }) => {
  return (
    <div className="cmp-dashboard__raw-data">
      {/* Atendentes online */}
      <OnlineAttendants attendants={attendants} classes={classes} />

      {/* Outros itens */}
      <DashboardItem
        title="Atd. Pendentes"
        icon={Schedule}
        value={counters.supportPending ? counters.supportPending : 0}
        classes={classes}
      />
      <DashboardItem
        title="Atd. Acontecendo"
        icon={HourglassEmpty}
        value={counters.supportHappening ? counters.supportHappening : 0}
        classes={classes}
      />
      <DashboardItem
        title="Atd. Realizados"
        icon={CheckCircle}
        value={counters.supportFinished ? counters.supportFinished : 0}
        classes={classes}
      />
      <DashboardItem
        title="Q. Serviço"
        icon={CheckCircle} // Pode ser qualquer ícone, altere conforme desejado
        value={serviceQuality !== null ? `${serviceQuality.toFixed(2)}%` : 'N/A'}
        classes={classes}
      />
    </div>
  );
};

export default DashboardHeader;
