import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";

const WavoipContext = createContext();

export const WavoipProvider = ({ children }) => {
    const [whatsappInstance, setWhatsappInstance] = useState(null);
    const [isCalling, setIsCalling] = useState(false);
    const { user } = useContext(AuthContext); 
    const [signalingEvents, setSignalingEvents] = useState([]); // Inicializado como array vazio

    const initializeInstance = async () => {
        if (!user || !user.whatsappId || !user.company.id) {
            return;
        }
    
        try {
            const response = await api.get('/api/get-tokenwavoip', {
                params: {
                    whatsappId: user.whatsappId,
                    companyId: user.company.id
                }
            });
    
            const { tokenwavoip } = response.data;
    
            if (!tokenwavoip) {
                console.error("Token Wavoip não encontrado.");
                return;
            }
    
            const Wavoip = require("wavoip-api");
            const instance = new Wavoip();
            const connectedInstance = instance.connect(tokenwavoip);
    
            // Captura qualquer evento que o socket receba
            connectedInstance.socket.onAny((event, ...args) => {
                console.log(`[DEBUG] - Evento recebido: ${event}`, args);
            });
    
            // Listener específico para o evento de sinalização
            connectedInstance.socket.on('signaling', (eventData) => {
                console.log("[DEBUG] - Evento de sinalização recebido:", eventData); // Log dos dados recebidos
    
                setSignalingEvents(prevEvents => {
                    const updatedEvents = [...prevEvents, eventData]; // Atualiza o estado com o novo evento
                    console.log("[DEBUG] - Lista de eventos de sinalização atualizada:", updatedEvents); // Log dos eventos atualizados
                    return updatedEvents; // Retorna o estado atualizado
                });
            });
    
            setWhatsappInstance(connectedInstance);
    
            connectedInstance.socket.on('connect', () => {
                console.log("[DEBUG] - Socket conectado com sucesso.");
            });
    
            connectedInstance.socket.on('disconnect', () => {
                console.log("[DEBUG] - Socket desconectado.");
            });
    
        } catch (err) {
            console.error("Erro ao inicializar a instância do Wavoip:", err);
        }
    };
    

    useEffect(() => {
        if (signalingEvents.length > 0) {
            console.log("[DEBUG] - Todos os eventos de sinalização até agora:", signalingEvents);
        }
    }, [signalingEvents]); // Isso vai rodar toda vez que signalingEvents for atualizado



    const startCall = (whatsappId) => {
        if (whatsappInstance) {
            try {
                whatsappInstance.callStart({ whatsappid: whatsappId });
                console.log("Chamada iniciada.");
                setIsCalling(true);
    
                // Verificar o status do dispositivo após iniciar a chamada
                whatsappInstance.getCurrentDeviceStatus()
                    .then(status => {
                        console.log("Status do dispositivo após iniciar a chamada:", status);
                    })
                    .catch(err => {
                        console.error("Erro ao obter o status do dispositivo:", err);
                    });
    
            } catch (err) {
                console.error("Erro ao iniciar a chamada:", err);
            }
        } else {
            console.warn("whatsappInstance não está disponível.");
        }
    };




    

    const endCall = () => {
        if (whatsappInstance) {
            try {
                whatsappInstance.endCall(); 
    
                whatsappInstance.getCurrentDeviceStatus()
                    .then(status => {
                        console.log("Status do dispositivo após encerrar a chamada:", status);
                    })
                    .catch(err => {
                        console.error("Erro ao obter o status do dispositivo:", err);
                    });
    
                setIsCalling(false); 
    
                whatsappInstance.socket.off('connect');
                whatsappInstance.socket.off('disconnect');
                whatsappInstance.socket.close();
    
            } catch (err) {
                console.error("Erro ao tentar encerrar a chamada:", err);
            }
        }
        initializeInstance();
    };
    


    return (
        <WavoipContext.Provider value={{ whatsappInstance, isCalling, startCall, endCall, signalingEvents }}>
            {children}
        </WavoipContext.Provider>
    );
};

export const useWavoip = () => useContext(WavoipContext);