import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  Chip,
  CircularProgress,
  Paper,
  Box,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useWhatsApps from '../../hooks/useWhatsApps';
import * as XLSX from 'xlsx'; // Importa a biblioteca XLSX


const ManipulateUsersComponent = () => {
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [usersToPromote, setUsersToPromote] = useState([]);
  const [usersToDemote, setUsersToDemote] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]); // Inicializado como array
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [promoteAdminsList, setPromoteAdminsList] = useState([]);
  const [demoteAdminsList, setDemoteAdminsList] = useState([]);
  const [removeUsersList, setRemoveUsersList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const { whatsApps, loading } = useWhatsApps();
  const [isSaving, setIsSaving] = useState(false);
  const [actions, setActions] = useState({
    promoteAdmins: false,
    demoteAdmins: false,
    addUsers: false,
    removeUsers: false,
    importContacts: true, // Importar contatos inicia como ativado
  });
  const [contactsList, setContactsList] = useState([]); // Estado para armazenar os contatos
  const [isLoadingContacts, setIsLoadingContacts] = useState(false); // Estado de carregamento de contatos

  // Função para buscar contatos com base no companyId armazenado no localStorage
  const fetchContacts = async () => {
    const companyId = localStorage.getItem('companyId');
    if (!companyId) {
      toast.error('Company ID não encontrado.');
      return;
    }

    try {
      setIsLoadingContacts(true);
      const { data } = await api.get('/api/groups/contacts', { params: { companyId } });
      const contacts = data.data.map((contact) => ({
        label: contact.name || contact.number,
        value: contact.number,
      }));
      setContactsList(contacts);
    } catch (err) {
      toast.error('Erro ao buscar contatos.');
    } finally {
      setIsLoadingContacts(false);
    }
  };

  useEffect(() => {
    if (selectedConnection) {
      const fetchGroups = async () => {
        try {
          setIsLoadingGroups(true);
          const { data } = await api.get(`/api/groups/list-groups`, {
            params: { whatsappId: selectedConnection },
          });
          setGroups(data.groups);
        } catch (err) {
          toast.error('Erro ao buscar grupos.');
        } finally {
          setIsLoadingGroups(false);
        }
      };
      fetchGroups();
    }
  }, [selectedConnection]);
// Função para importar o arquivo Excel e extrair os números
// Função para importar o arquivo Excel e extrair os números
// Função para importar o arquivo Excel ou CSV e extrair os números
// Função para importar o arquivo Excel ou CSV e extrair os números
const handleImportXLS = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = e.target.result;
      let workbook;
  
      // Verifica se o arquivo é CSV ou XLSX
      if (file.name.endsWith('.csv')) {
        // Lê o CSV diretamente
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        // Lê arquivos Excel (xlsx/xls)
        workbook = XLSX.read(data, { type: 'array' });
      }
  
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      // Lê os dados da planilha ou CSV
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      // Supondo que os números estejam na primeira coluna
      const importedNumbers = jsonData
        .map((row) => row[0]) // Pega a primeira coluna
        .filter(Boolean); // Filtra valores não nulos ou indefinidos
  
      // Atualiza os estados de cada campo com os números importados
      setUsersToAdd((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToRemove((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToPromote((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
      setUsersToDemote((prevUsers) => [...prevUsers, ...importedNumbers.map(String)]);
    };
  
    // Se for CSV, usa 'readAsBinaryString', senão, usa 'readAsArrayBuffer'
    if (file.name.endsWith('.csv')) {
      reader.readAsBinaryString(file); // Para CSV
    } else {
      reader.readAsArrayBuffer(file); // Para XLS/XLSX
    }
  };
  
  
  
  const fetchGroupUsers = async (groupId) => {
    try {
      const { data } = await api.post('/api/groups/list-group-participants', {
        whatsappId: selectedConnection,
        groupIds: [groupId],
      });

      const users = data.data[0].participants.map((participant) => ({
        label: participant.name || participant.id.replace('@s.whatsapp.net', ''),
        value: participant.id.replace('@s.whatsapp.net', ''),
      }));

      setPromoteAdminsList(users);
      setDemoteAdminsList(users);
      setRemoveUsersList(users);
    } catch (err) {
      toast.error('Erro ao buscar usuários do grupo.');
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchGroupUsers(selectedGroup);
    }
  }, [selectedGroup]);

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
  
    if (name === 'addUsers') {
      setActions((prevActions) => ({
        ...prevActions,
        addUsers: checked,
        removeUsers: false, // Desativa e limpa
        promoteAdmins: false,
        demoteAdmins: false,
      }));
  
      if (checked) {
        fetchContacts(); // Chama a função para buscar contatos
      } else {
        setUsersToAdd([]); // Limpa os usuários a adicionar
      }
  
      // Limpa os usuários dos outros switches
      setUsersToRemove([]);
      setUsersToPromote([]);
      setUsersToDemote([]);
    } 
    else if (name === 'removeUsers') {
      setActions((prevActions) => ({
        ...prevActions,
        removeUsers: checked,
        addUsers: false, // Desativa e limpa
        promoteAdmins: false,
        demoteAdmins: false,
      }));
  
      if (!checked) {
        setUsersToRemove([]); // Limpa os usuários a remover
      }
  
      // Limpa os usuários dos outros switches
      setUsersToAdd([]);
      setUsersToPromote([]);
      setUsersToDemote([]);
    } 
    else if (name === 'promoteAdmins') {
      setActions((prevActions) => ({
        ...prevActions,
        promoteAdmins: checked,
        addUsers: false, // Desativa e limpa
        removeUsers: false,
        demoteAdmins: false,
      }));
  
      if (!checked) {
        setUsersToPromote([]); // Limpa os admins a promover
      }
  
      setUsersToRemove(checked ? usersToRemove : []);  // Limpa o campo "Remover Usuários"
      setUsersToAdd([]);
      setUsersToRemove([]);
      setUsersToDemote([]);
    } 
    else if (name === 'demoteAdmins') {
      setActions((prevActions) => ({
        ...prevActions,
        demoteAdmins: checked,
        addUsers: false, // Desativa e limpa
        removeUsers: false,
        promoteAdmins: false,
      }));
  
      if (!checked) {
        setUsersToDemote([]); // Limpa os admins a demover
      }
  
      setUsersToDemote(checked ? usersToDemote : []);  // Limpa o campo "Demover Admins"
      setUsersToAdd([]);
      setUsersToRemove([]);
      setUsersToPromote([]);
    } 
    // Mantém o comportamento do "Importar Contatos" intacto
    else if (name === 'importContacts') {
      setActions((prevActions) => ({
        ...prevActions,
        importContacts: checked,
      }));
    }
  };
  const formatPhoneNumbers = (numbers) => {
    return numbers.map(number => {
      // Remover espaços e caracteres desnecessários
      number = number.replace(/\D/g, '');
  
      // Verifica se o número tem 13 dígitos e remove o primeiro 9 se necessário
      if (number.length === 13 && number.startsWith('9', 4)) {
        return number.slice(0, 4) + number.slice(5);
      }
      
      return number;
    });
  };
  
  const handleExecute = async () => {
    if (!selectedGroup) {
      toast.error('Selecione um grupo.');
      return;
    }
  
    setIsSaving(true);
    try {
      const usersToAddArray = formatPhoneNumbers(usersToAdd);
      const usersToRemoveArray = formatPhoneNumbers(usersToRemove);
      const usersToPromoteArray = formatPhoneNumbers(usersToPromote);
      const usersToDemoteArray = formatPhoneNumbers(usersToDemote);
  
      if (actions.promoteAdmins && usersToPromote.length > 0) {

        await api.post('/api/groups/promote-admins', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToPromoteArray,
        });
      }
  
      if (actions.demoteAdmins && usersToDemote.length > 0) {
    
        await api.post('/api/groups/demote-admins', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToDemoteArray,
        });
      }
  
      if (actions.addUsers && usersToAddArray.length > 0) {
    
        await api.post('/api/groups/add-users', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToAddArray,
        });
      }
  
      if (actions.removeUsers && usersToRemoveArray.length > 0) {
      
        await api.post('/api/groups/remove-users', {
          whatsappId: selectedConnection,
          groupId: selectedGroup,
          users: usersToRemoveArray,
        });
      }
  
      toast.success('Ações aplicadas com sucesso!');
    } catch (error) {
      console.error("Erro ao aplicar as ações:", error);
      toast.error('Erro ao aplicar as ações.');
    } finally {
      setIsSaving(false);
    }
  };
  

  const handleClear = () => {
    setSelectedConnection('');
    setSelectedGroup('');
    setUsersToPromote([]);
    setUsersToDemote([]);
    setUsersToAdd([]);
    setUsersToRemove([]);
    setActions({
      promoteAdmins: false,
      demoteAdmins: false,
      addUsers: false,
      removeUsers: false,
      importContacts: true, // Resetar com Importar Contatos ativado
    });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                Manipular Usuários
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  options={whatsApps.filter((contact) => contact.type === null)}
                  getOptionLabel={(option) => option.name || option.number}
                  renderInput={(params) => <TextField {...params} label="Conexão" variant="outlined" />}
                  value={whatsApps.filter((contact) => contact.type === null).find((whatsApp) => whatsApp.id === selectedConnection) || null}
                  onChange={(event, newValue) => setSelectedConnection(newValue ? newValue.id : '')}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={groups}
                  getOptionLabel={(option) => option.subject}
                  renderInput={(params) => <TextField {...params} label="Grupo" variant="outlined" />}
                  value={groups.find((group) => group.id === selectedGroup) || null}
                  onChange={(event, newValue) => setSelectedGroup(newValue ? newValue.id : '')}
                  disabled={!selectedConnection || groups.length === 0 || isLoadingGroups}
                />
                {isLoadingGroups && <CircularProgress size={24} />}
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.importContacts}
                      onChange={handleSwitchChange}
                      name="importContacts"
                      color="primary"
                    />
                  }
                  label="Importar Contatos"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.addUsers}
                      onChange={handleSwitchChange}
                      name="addUsers"
                      color="primary"
                    />
                  }
                  label="Adicionar Usuários"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.removeUsers}
                      onChange={handleSwitchChange}
                      name="removeUsers"
                      color="primary"
                    />
                  }
                  label="Remover Usuários"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.promoteAdmins}
                      onChange={handleSwitchChange}
                      name="promoteAdmins"
                      color="primary"
                    />
                  }
                  label="Promover Admins"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={actions.demoteAdmins}
                      onChange={handleSwitchChange}
                      name="demoteAdmins"
                      color="primary"
                    />
                  }
                  label="Demover Admins"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              {actions.addUsers && (
                <Grid item xs={12}>
                  {actions.importContacts ? (
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={contactsList} // Lista de contatos buscada da API
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecionar Contatos para Adicionar" variant="outlined" />
                      )}
                      value={contactsList.filter((user) => usersToAdd.includes(user.value))}
                      onChange={(event, newValue) => {
                        setUsersToAdd(newValue.map((user) => user.value));
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const optionValue = typeof option === 'object' ? option.value : option;

                            const user = contactsList.find((user) => user.value === optionValue);
                            return (
                            <Chip
                              key={optionValue}
                              label={user ? user.label : 'Nome não disponível'}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  ) : (
                    <>
                      <TextField
                        label="Adicionar Usuários (separado por vírgula)"
                        variant="outlined"
                        fullWidth
                        value={usersToAdd.join(',')}
                        onChange={(e) => setUsersToAdd(e.target.value.split(',').map((user) => user.trim()))}
                      />
                                            <input
                        accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
                        style={{ display: 'none' }}
                        id="import-excel"
                        type="file"
                        onChange={handleImportXLS}
                        />
                        <label htmlFor="import-excel">
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            style={{ marginTop: '10px' }}
                            component="span"
                        >
                            IMPORTAR DE XLS/CSV
                        </Button>
                        </label>


                    </>
                  )}
                </Grid>
              )}
            

  {/* Campo de remover usuários */}
  {actions.removeUsers && (
    <Grid item xs={12}>
      {actions.importContacts ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={removeUsersList}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Selecionar Contatos para Remover" variant="outlined" />
          )}
          value={removeUsersList.filter((user) => usersToRemove.includes(user.value))}
          onChange={(event, newValue) => {
            setUsersToRemove(newValue.map((user) => user.value));
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const user = removeUsersList.find((user) => user.value === option.value);
              return (
                <Chip
                  key={option.value}
                  label={user ? user.label : 'Nome não disponível'}
                  {...getTagProps({ index })}
                />
              );
            })
          }
        />
      ) : (
        <>
          <TextField
            label="Remover Usuários (separado por vírgula)"
            variant="outlined"
            fullWidth
            value={usersToRemove}
            onChange={(e) => setUsersToRemove(e.target.value.split(',').map((user) => user.trim()))}
          />
<input
  accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
  style={{ display: 'none' }}
  id="import-excel"
  type="file"
  onChange={handleImportXLS}
/>
<label htmlFor="import-excel">
  <Button
    variant="contained"
    fullWidth
    color="primary"
    style={{ marginTop: '10px' }}
    component="span"
  >
    IMPORTAR DE XLS/CSV
  </Button>
</label>


        </>
      )}
    </Grid>
  )}

  {/* Campo de promover admins */}
  {actions.promoteAdmins && (
    <Grid item xs={12}>
      {actions.importContacts ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={promoteAdminsList}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Selecionar Admins para Promover" variant="outlined" />
          )}
          value={promoteAdminsList.filter((user) => usersToPromote.includes(user.value))}
          onChange={(event, newValue) => {
            setUsersToPromote(newValue.map((user) => user.value));
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const user = promoteAdminsList.find((user) => user.value === option.value);
              return (
                <Chip
                  key={option.value}
                  label={user ? user.label : 'Nome não disponível'}
                  {...getTagProps({ index })}
                />
              );
            })
          }
        />
      ) : (
        <>
          <TextField
            label="Promover Admins (separado por vírgula)"
            variant="outlined"
            fullWidth
            value={usersToPromote}
            onChange={(e) => setUsersToPromote(e.target.value.split(',').map((user) => user.trim()))}
          />
          {/* Botão de importar XLS aparece junto do campo de texto */}
          <input
  accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
  style={{ display: 'none' }}
  id="import-excel"
  type="file"
  onChange={handleImportXLS}
/>
<input
  accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
  style={{ display: 'none' }}
  id="import-excel"
  type="file"
  onChange={handleImportXLS}
/>
<label htmlFor="import-excel">
  <Button
    variant="contained"
    fullWidth
    color="primary"
    style={{ marginTop: '10px' }}
    component="span"
  >
    IMPORTAR DE XLS/CSV
  </Button>
</label>


        </>
      )}
    </Grid>
  )}

  {/* Campo de demover admins */}
  {actions.demoteAdmins && (
    <Grid item xs={12}>
      {actions.importContacts ? (
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={demoteAdminsList}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField {...params} label="Selecionar Admins para Demover" variant="outlined" />
          )}
          value={demoteAdminsList.filter((user) => usersToDemote.includes(user.value))}
          onChange={(event, newValue) => {
            setUsersToDemote(newValue.map((user) => user.value));
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const user = demoteAdminsList.find((user) => user.value === option.value);
              return (
                <Chip
                  key={option.value}
                  label={user ? user.label : 'Nome não disponível'}
                  {...getTagProps({ index })}
                />
              );
            })
          }
        />
      ) : (
        <>
          <TextField
            label="Demover Admins (separado por vírgula)"
            variant="outlined"
            fullWidth
            value={usersToDemote}
            onChange={(e) => setUsersToDemote(e.target.value.split(',').map((user) => user.trim()))}
          />
          {/* Botão de importar XLS aparece junto do campo de texto */}
          <input
            accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
            style={{ display: 'none' }}
            id="import-excel"
            type="file"
            onChange={handleImportXLS}
            />
        <input
  accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
  style={{ display: 'none' }}
  id="import-excel"
  type="file"
  onChange={handleImportXLS}
/>
<input
  accept=".xlsx, .xls, .csv" // Adiciona a extensão .csv
  style={{ display: 'none' }}
  id="import-excel"
  type="file"
  onChange={handleImportXLS}
/>
<label htmlFor="import-excel">
  <Button
    variant="contained"
    fullWidth
    color="primary"
    style={{ marginTop: '10px' }}
    component="span"
  >
    IMPORTAR DE XLS/CSV
  </Button>
</label>



        </>
      )}
    </Grid>
  )}
</Grid>


            

            <Grid container spacing={2} style={{ marginTop: '40px' }}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleExecute}
                  disabled={
                    isSaving ||
                    !selectedGroup ||
                    (!usersToAdd && !usersToPromote.length && !usersToDemote.length && !usersToRemove.length)
                  }
                >
                  {isSaving ? <CircularProgress size={24} /> : 'Executar'}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" fullWidth color="default" onClick={handleClear}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ManipulateUsersComponent;
