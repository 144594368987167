import React, { useState, useEffect, useContext } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import {
  Button,
  Typography,
  Divider,
  Grid,
  TextField,
  Modal,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  LinearProgress,
  Paper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GroupAdd, PeopleAlt } from '@material-ui/icons';
import { AuthContext } from '../../context/Auth/AuthContext';
import api from '../../services/api';
import { toast } from 'react-toastify';
import useWhatsApps from '../../hooks/useWhatsApps';
import * as XLSX from 'xlsx';

// Função auxiliar para exibir os dados dos participantes
const ParticipantsTable = ({ participants }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (participants.length === 0) {
    return <Typography>Nenhum participante encontrado.</Typography>;
  }

  const formatPhoneNumber = (id) => id.replace('@s.whatsapp.net', '');

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell> {/* Coluna para o número */}
              <TableCell>Nome</TableCell>   {/* Coluna para o nome */}
              <TableCell align="right">Admin Status</TableCell> {/* Coluna para o status de administrador */}
              <TableCell align="right">Nome do Grupo</TableCell> {/* Coluna para o nome do grupo */}
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((participant, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {formatPhoneNumber(participant.id)} {/* Remover @s.whatsapp.net */}
                </TableCell>
                <TableCell>
                  {participant.name || 'Desconhecido'} {/* Exibir nome ou "Desconhecido" */}
                </TableCell>
                <TableCell align="right">
                  {participant.isAdmin === 'superadmin'
                    ? 'Super Admin'
                    : participant.isAdmin === 'admin'
                    ? 'Admin'
                    : 'Não'}
                </TableCell>
                <TableCell align="right">
                  {participant.groupName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={participants.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
      />
    </>
  );
};


const CreateGroupComponent = () => {
  const [isCreateGroupOpen, setCreateGroupOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [useDropdown, setUseDropdown] = useState(false);
  const [contactNumber, setContactNumber] = useState('');
  const [selectedConnection, setSelectedConnection] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]); // Agora você pode selecionar múltiplos grupos
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupCount, setGroupCount] = useState(1);
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  const { user } = useContext(AuthContext);
  const { whatsApps, loading } = useWhatsApps();

  const handleOpenCreateGroup = () => setCreateGroupOpen(true);
  const handleCloseCreateGroup = () => setCreateGroupOpen(false);

  useEffect(() => {
    if (isCreateGroupOpen) {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get('/contacts');
          setContacts(data.contacts);
        } catch (err) {
          toast.error('Erro ao buscar contatos.');
        }
      };
      fetchContacts();
    }
  }, [isCreateGroupOpen]);

  // Buscar grupos da conexão selecionada
  useEffect(() => {
    if (selectedConnection) {
      const fetchGroups = async () => {
        try {
          const { data } = await api.get(`/api/groups/list-groups`, {
            params: { whatsappId: selectedConnection }
          });
          setGroups(data.groups);
        } catch (err) {
          toast.error('Erro ao buscar grupos.');
        }
      };
      fetchGroups();
    }
  }, [selectedConnection]);

  // Buscar participantes dos grupos selecionados
  const handleListParticipants = async () => {
    if (!selectedConnection || selectedGroups.length === 0) {
      toast.error('Selecione uma conexão e ao menos um grupo.');
      return;
    }

    setLoadingParticipants(true);

    try {
      const { data } = await api.post(`/api/groups/list-group-participants`, {
        whatsappId: selectedConnection,
        groupIds: selectedGroups // Enviando todos os grupos selecionados
      });

      let allParticipants = [];
      data.data.forEach(group => {
        group.participants.forEach(participant => {
          allParticipants.push({
            ...participant,
            groupName: group.groupName
          });
        });
      });

      if (allParticipants.length > 0) {
        setParticipants(allParticipants);
        toast.success('Participantes listados com sucesso!');
      } else {
        setParticipants([]); // Define como vazio se não houver participantes
        toast.info('Nenhum participante encontrado.');
      }
    } catch (err) {
      toast.error('Erro ao listar participantes.');
    } finally {
      setLoadingParticipants(false);
    }
  };

  // Exportar participantes para XLS
  const handleExportToXLS = () => {
    if (participants.length === 0) {
      toast.error('Nenhum participante para exportar.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(participants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Participantes');
    XLSX.writeFile(workbook, 'participantes.xlsx');
    toast.success('Exportado com sucesso!');
  };

  // Limpar os dropdowns e participantes
  const handleClear = () => {
    setSelectedConnection('');
    setSelectedGroups([]);
    setGroups([]);
    setParticipants([]);
  };

  const handleCreateGroup = async () => {
    const formattedContactNumber = useDropdown
      ? null
      : formatContactNumber(contactNumber);

    if (useDropdown && selectedContacts.length === 0) {
      toast.error('Selecione ao menos um contato.');
      return;
    }

    if (!useDropdown && !formattedContactNumber) {
      toast.error('Número inválido. O formato deve conter o código DDD e o número.');
      return;
    }

    if (!groupName) {
      toast.error('Nome do grupo é obrigatório.');
      return;
    }

    if (!selectedConnection) {
      toast.error('Selecione uma conexão.');
      return;
    }

    try {
      setIsLoading(true);

      const participants = useDropdown
        ? selectedContacts.map(contact => `${contact.number}@s.whatsapp.net`)
        : [formattedContactNumber];

      for (let i = 0; i < groupCount; i++) {
        await api.post('/api/groups/create-group', {
          whatsappId: selectedConnection,
          groupName: groupName,
          participants,
        });
      }

      toast.success(`Criado ${groupCount} grupo(s) com sucesso!`);
      handleCloseCreateGroup();
    } catch (err) {
      toast.error('Erro ao criar grupo(s).');
    } finally {
      setIsLoading(false);
    }
  };

  const formatContactNumber = (number) => {
    let formattedNumber = number.replace(/[^0-9]/g, '');

    if (formattedNumber.length === 13 && formattedNumber[4] === '9') {
      formattedNumber = formattedNumber.slice(0, 4) + formattedNumber.slice(5);
    }

    if (formattedNumber.length !== 12) {
      toast.error('Número inválido. O formato deve conter o código DDD e o número.');
      return null;
    }

    return `${formattedNumber}@s.whatsapp.net`;
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <GroupAdd style={{ marginRight: '10px', fontSize: '30px' }} />
              <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                Gerador de Grupos
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Button variant="contained" color="primary" fullWidth onClick={handleOpenCreateGroup}>
                  Criar Grupos em Massa
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <PeopleAlt style={{ marginRight: '10px', fontSize: '30px' }} />
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Listar Participantes
              </Typography>
            </Box>
            <Divider style={{ marginBottom: '2rem' }} />
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6}>
                {/* Autocomplete para seleção única de conexão */}
                <Autocomplete
                  options={whatsApps.filter((contact) => contact.type === null)}
                  getOptionLabel={(option) => option.name || option.number}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name || option.number}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Conexão" variant="outlined" />
                  )}
                  value={whatsApps.filter((contact) => contact.type === null).find(whatsApp => whatsApp.id === selectedConnection) || null}
                  onChange={(event, newValue) => setSelectedConnection(newValue ? newValue.id : '')}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Autocomplete para seleção múltipla de grupos */}
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={groups}
                  getOptionLabel={(option) => option.subject}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.subject}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Grupos" variant="outlined" />
                  )}
                  value={groups.filter(group => selectedGroups.includes(group.id))}
                  onChange={(event, newValue) => setSelectedGroups(newValue.map(group => group.id))}
                  disabled={!selectedConnection || groups.length === 0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '40px' }}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleListParticipants}
                  disabled={selectedGroups.length === 0}
                >
                  {loadingParticipants ? <CircularProgress size={24} /> : 'Listar Participantes'}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleExportToXLS}
                  disabled={participants.length === 0}
                >
                  Exportar para XLS
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" fullWidth color="default" onClick={handleClear}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Tabela de Participantes */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Lista de Participantes
            </Typography>
            <Divider style={{ marginBottom: '2rem' }} />

            {loadingParticipants ? (
              <CircularProgress />
            ) : (
              <ParticipantsTable participants={participants} />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Modal open={isCreateGroupOpen} onClose={handleCloseCreateGroup}>
        <Box
          style={{
            background: 'white',
            padding: '30px',
            margin: '4% auto',
            maxWidth: '600px',
            borderRadius: '20px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <GroupAdd color="primary" style={{ fontSize: '40px', marginRight: '15px' }} />
            <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
              Criar Grupo
            </Typography>
          </Box>
          <Divider style={{ marginBottom: '1.5rem' }} />
          <FormControl fullWidth style={{ marginBottom: '1rem' }}>
            <InputLabel>Conexão</InputLabel>
            <Select
              value={selectedConnection}
              onChange={(e) => setSelectedConnection(e.target.value)}
              disabled={loading}
            >
              {whatsApps.filter((contact) => {
                return contact.type === null;
              }).map((whatsApp) => (
                <MenuItem key={whatsApp.id} value={whatsApp.id}>
                  {whatsApp.name || whatsApp.number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Nome do Grupo"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Quantidade de Grupos"
            type="number"
            value={groupCount}
            onChange={(e) => setGroupCount(e.target.value)}
            fullWidth
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />
          <FormControlLabel
            control={<Switch checked={useDropdown} onChange={() => setUseDropdown(!useDropdown)} color="primary" />}
            label="Definir Contato / Selecionar Contato"
            style={{ marginBottom: '1rem' }}
          />
          {useDropdown ? (
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={contacts.filter(contact => !contact.isGroup)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Selecionar Contato" variant="outlined" />}
              value={selectedContacts}
              onChange={(event, newValue) => setSelectedContacts(newValue)}
            />
          ) : (
            <TextField
              label="Número do Participante"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
              variant="outlined"
            />
          )}
          <Divider style={{ margin: '1.5rem 0' }} />
          {isLoading && <LinearProgress style={{ marginBottom: '1.5rem' }} />}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={handleCloseCreateGroup}
                fullWidth
                color="primary"
                style={{
                  fontWeight: 'bold',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  border: '2px solid',
                  transition: 'background 0.3s ease',
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={handleCreateGroup}
                fullWidth
                disabled={isLoading}
                color="primary"
                style={{
                  fontWeight: 'bold',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  transition: 'background 0.3s ease',
                }}
              >
                {isLoading ? 'Criando...' : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateGroupComponent;
