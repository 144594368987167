import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { 
  Table, TableBody, TableCell, TableHead, TableRow, 
  Button, TextField, MenuItem, Grid, Paper, Typography 
} from '@material-ui/core';

// Interface simples para carregar as empresas no dropdown
interface SimpleCompany {
  id: number;
  name: string;
}

interface Company {
  companyId: number;
  companyName: string;
  planId: number;
  planName: string;
  messageValue: number | string;
  messageCount: number;
  totalMessageValue: number | string;
  planMonthlyValue: number | string;
  totalValue: number | string;
}

const MessagesByCompany = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [availableCompanies, setAvailableCompanies] = useState<SimpleCompany[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('all'); // "all" como padrão para selecionar todas
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  // Carrega a lista de empresas para o filtro de seleção
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.get('/api/messages/companies');
        setAvailableCompanies([{ id: 'all', name: 'Todas as Empresas' }, ...response.data]); // Adiciona "Todas as Empresas"
      } catch (err) {
        console.error('Erro ao buscar empresas', err);
      }
    };
    fetchCompanies();
  }, []);

  // Função chamada ao clicar em "Filtrar"
  const handleFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const params = {
        startDate,
        endDate,
        ...(selectedCompanyId !== 'all' && { companyId: selectedCompanyId }) // Só envia companyId se não for "all"
      };

      const response = await api.get('/api/messages/messagesByCompany', {
        params,
      });
      setCompanies(response.data);
    } catch (err) {
      setError('Erro ao buscar mensagens por empresa');
      console.error('Erro na requisição:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Mensagens por Empresa
      </Typography>

      <Paper elevation={3} style={{ padding: '1rem', marginBottom: '2rem' }}>
        <form onSubmit={handleFilter}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label="Empresa"
                value={selectedCompanyId}
                onChange={(e) => setSelectedCompanyId(e.target.value)}
                fullWidth
              >
                {availableCompanies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Fim"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {companies.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          Nenhuma mensagem encontrada.
        </Typography>
      ) : (
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Nome da Empresa</TableCell>
                <TableCell align="left">ID da Empresa</TableCell>
                <TableCell align="left">Nome do Plano</TableCell>
                <TableCell align="left">ID do Plano</TableCell>
                <TableCell align="left">Total de Mensagens (Baseado em 24h)</TableCell>
                <TableCell align="left">Valor por Mensagem</TableCell>
                <TableCell align="left">Valor Total das Mensagens</TableCell>
                <TableCell align="left">Valor Mensal do Plano</TableCell>
                <TableCell align="left">Valor Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => (
                <TableRow key={company.companyId}>
                  <TableCell align="left">{company.companyName || 'Sem Nome'}</TableCell>
                  <TableCell align="left">{company.companyId}</TableCell>
                  <TableCell align="left">{company.planName || 'Sem Plano'}</TableCell>
                  <TableCell align="left">{company.planId}</TableCell>
                  <TableCell align="left">{company.messageCount}</TableCell>
                  <TableCell align="left">
                    {Number(company.messageValue).toFixed(2)} {/* Converte para número antes de formatar */}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.totalMessageValue).toFixed(2)} {/* Exibe o valor total das mensagens */}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.planMonthlyValue).toFixed(2)} {/* Exibe o valor mensal do plano */}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.totalValue).toFixed(2)} {/* Exibe o valor total */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export default MessagesByCompany;
